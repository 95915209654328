import React, { useState } from 'react';
import { Box, IconButton, Tooltip } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';

const CodeBlock = ({ code, language = 'javascript' }) => {
    const [copied, setCopied] = useState(false);
    
    const handleCopy = () => {
        navigator.clipboard.writeText(code);
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
    };

    return (
        <Box sx={{ 
            position: 'relative',
            mb: 3,
            borderRadius: '12px',
            overflow: 'hidden',
            boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
            transition: 'transform 0.2s ease, box-shadow 0.2s ease',
            '&:hover': {
                boxShadow: '0 8px 30px rgba(0, 0, 0, 0.15)',
                transform: 'translateY(-2px)'
            }
        }}>
            <Box 
                sx={{ 
                    backgroundColor: '#1e1e2e', 
                    p: 2, 
                    borderRadius: '12px',
                    position: 'relative',
                    overflow: 'auto',
                    '&::-webkit-scrollbar': {
                        height: '8px',
                        width: '8px',
                    },
                    '&::-webkit-scrollbar-track': {
                        background: 'rgba(255, 255, 255, 0.1)',
                        borderRadius: '4px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        background: 'rgba(255, 255, 255, 0.2)',
                        borderRadius: '4px',
                    },
                    '&::-webkit-scrollbar-thumb:hover': {
                        background: 'rgba(255, 255, 255, 0.3)',
                    },
                }}
            >
                <Tooltip title={copied ? "Copied!" : "Copy to clipboard"} placement="top">
                    <IconButton 
                        onClick={handleCopy} 
                        sx={{ 
                            position: 'absolute', 
                            top: 8, 
                            right: 8, 
                            color: copied ? 'success.main' : 'rgba(255, 255, 255, 0.7)',
                            backgroundColor: 'rgba(0, 0, 0, 0.3)',
                            backdropFilter: 'blur(4px)',
                            '&:hover': {
                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                color: 'white'
                            },
                            transition: 'all 0.2s ease',
                            zIndex: 2,
                            padding: '4px',
                        }}
                        size="small"
                    >
                        <ContentCopyIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <SyntaxHighlighter 
                    language={language}
                    style={atomDark}
                    customStyle={{
                        margin: 0,
                        borderRadius: 8,
                        fontSize: '0.9rem',
                        padding: '16px',
                        backgroundColor: 'transparent',
                    }}
                    wrapLongLines={true}
                >
                    {code}
                </SyntaxHighlighter>
            </Box>
        </Box>
    );
};

export default CodeBlock; 