import React, { useState, useEffect, useContext, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { CircularProgress } from '@mui/material';
import { red } from '@mui/material/colors';
import { AuthContext } from '../AuthContext';
import { Paper } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import PasswordIcon from '@mui/icons-material/Password';
import InputAdornment from '@mui/material/InputAdornment';
import InfoIcon from '@mui/icons-material/Info';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://entrinsic.io/">
        Entrinsic Connect
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const defaultTheme = createTheme();

// Create a new LoginForm component that uses the reCAPTCHA hook
function LoginForm({ setShowNotVerifiedModal, setShowAlert, navigate, setShowBrowserVerificationModal }) {
    const [loggingIn, setLoggingIn] = useState(false);
    const { setUser } = useContext(AuthContext);
    const { executeRecaptcha } = useGoogleReCaptcha();
    const captchaToken = useRef(null);
    const theme = useTheme();

    const handleReCaptchaVerify = async () => {
        if (!executeRecaptcha) {
            console.log('Execute recaptcha not yet available');
            return null;
        }
        return await executeRecaptcha('login');
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoggingIn(true);

        const data = new FormData(event.currentTarget);
        let email = data.get('email');
        let password = data.get('password');

        // Only get captcha if not a developer email
        if (!email.endsWith('@entrinsic.io')) {
            captchaToken.current = await handleReCaptchaVerify();
        }

        try {
            const response = await fetch('/api/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ 
                    email, 
                    password,
                    ...(captchaToken.current && { captchaToken: captchaToken.current })
                })
            });
            
            // Check if the rate limit has been exceeded
            if (response.status === 429) {
              // Handle rate limit exceeded
              const errorData = await response.json();
              alert(errorData.message); // Show an alert, or you can use a more user-friendly way to display the error
              setLoggingIn(false); // Hide the spinner
              return;
          }

            const data = await response.json();

            if (data.success) {
              // create a user json object
               const user = {
                 id: data.id,
                 role: data.role,
                  email: data.email,
                  partnerId: data.partnerId,
                  partnerName: data.partnerName,
                  is_partner_user: data.is_partner_user
               };

                setUser(user);
                const params = new URLSearchParams(window.location.search);
                let redirectUrl;

                
                if (data.role =='KIOSK')
                {
                  redirectUrl = params.get('redirect') || '/kiosk';
                  window.location.href = redirectUrl; // this is a bit different, as we are navigating outside the React app
                  return;
                }
                
                // otherwise we're still in the React app
                if (data.role=='SUPER_ADMIN')
                {
                  redirectUrl = params.get('redirect') || '/admin/customers';
                }
                else {
                  redirectUrl = params.get('redirect') || '/client-admin/';
                }
                navigate(decodeURIComponent(redirectUrl));  
        
            } else {
                setLoggingIn(false); // Hide the spinner
                if (data.reason === 'browser_verification_required') {
                    setShowBrowserVerificationModal(true);
                } else if (data.reason === 'not_verified') {
                    setShowNotVerifiedModal(true);  
                    sessionStorage.setItem('verificationToken', data.token);
                } else {
                    alert(data.message);
                }
            }
        } catch (error) {
            console.error("Login error:", error);
            setLoggingIn(false);
        }
    };

    return (
        <Box 
            component="form" 
            onSubmit={handleSubmit} 
            noValidate 
            sx={{ 
                mt: 3,
                width: '100%'
            }}
        >
            <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <EmailIcon color="primary" />
                        </InputAdornment>
                    ),
                }}
                sx={{
                    '& .MuiOutlinedInput-root': {
                        borderRadius: 2,
                        transition: 'all 0.2s ease',
                        '&:hover': {
                            boxShadow: '0 0 0 2px rgba(58, 123, 213, 0.1)'
                        },
                        '&.Mui-focused': {
                            boxShadow: '0 0 0 2px rgba(58, 123, 213, 0.2)'
                        }
                    }
                }}
            />
            <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <PasswordIcon color="primary" />
                        </InputAdornment>
                    ),
                }}
                sx={{
                    '& .MuiOutlinedInput-root': {
                        borderRadius: 2,
                        transition: 'all 0.2s ease',
                        '&:hover': {
                            boxShadow: '0 0 0 2px rgba(58, 123, 213, 0.1)'
                        },
                        '&.Mui-focused': {
                            boxShadow: '0 0 0 2px rgba(58, 123, 213, 0.2)'
                        }
                    }
                }}
            />
            
            <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ 
                    mt: 3, 
                    mb: 2,
                    py: 1.5,
                    borderRadius: 2,
                    fontWeight: 600,
                    boxShadow: '0 4px 12px rgba(58, 123, 213, 0.2)',
                    transition: 'all 0.2s ease',
                    '&:hover': {
                        boxShadow: '0 6px 16px rgba(58, 123, 213, 0.3)',
                        transform: 'translateY(-2px)'
                    }
                }}
                disabled={loggingIn}
            >
                { loggingIn ? <CircularProgress size={24} /> : 'Sign In' }
            </Button>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <Link 
                        href="/reset-password" 
                        variant="body2"
                        sx={{
                            textDecoration: 'none',
                            color: theme.palette.primary.main,
                            fontWeight: 500,
                            transition: 'color 0.2s ease',
                            '&:hover': {
                                color: theme.palette.primary.dark,
                                textDecoration: 'underline'
                            }
                        }}
                    >
                        Forgot password?
                    </Link>
                </Grid>
                <Grid item xs={12} sm={6} sx={{ textAlign: { sm: 'right' } }}>
                    <Link 
                        href="/signup" 
                        variant="body2"
                        sx={{
                            textDecoration: 'none',
                            color: theme.palette.primary.main,
                            fontWeight: 500,
                            transition: 'color 0.2s ease',
                            '&:hover': {
                                color: theme.palette.primary.dark,
                                textDecoration: 'underline'
                            }
                        }}
                    >
                        {"Don't have an account? Sign Up"}
                    </Link>
                </Grid>
            </Grid>
        </Box>
    );
}

// Main SignIn component
export default function SignIn() {
    const [showNotVerifiedModal, setShowNotVerifiedModal] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [showPasswordResetAlert, setShowPasswordResetAlert] = useState(false);
    const [showPasswordResetSuccessAlert, setShowPasswordResetSuccessAlert] = useState(false);
    const [showBrowserVerificationModal, setShowBrowserVerificationModal] = useState(false);
    const [showBrowserVerificationSuccessAlert, setShowBrowserVerificationSuccessAlert] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const mobileBreakpoint = 600;
    const isMobile = window.innerWidth <= mobileBreakpoint;
    const theme = useTheme();

    const { setUser } = useContext(AuthContext);
    const { executeRecaptcha } = useGoogleReCaptcha();
    const captchaToken = React.useRef(null);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        if (params.get('passwordReset') === 'true') {
            setShowPasswordResetAlert(true);
            setTimeout(() => setShowPasswordResetAlert(false), 10000);  
        }
    }, [location.search]);

    useEffect(() => {
      const params = new URLSearchParams(location.search);
      if (params.get('passwordResetSuccess') === 'true') {
          setShowPasswordResetSuccessAlert(true);
          setTimeout(() => setShowPasswordResetSuccessAlert(false), 10000);  
      }
  }, [location.search]);
  
  const handleResend = async () => {
    const token = sessionStorage.getItem('verificationToken');
    
    try {
        const response = await fetch(`/api/notverified?resend=true&token=${token}`);
        if (response.ok) {
            // Maybe show a toast or some feedback saying the email was resent
            setShowAlert(true); // Show the alert
            setTimeout(() => setShowAlert(false), 10000); 
            setShowNotVerifiedModal(false);
        } else {
            console.error('Failed to resend verification email');
        }
    } catch (error) {
        console.error("Error:", error);
    }
};

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        if (params.get('verified') === 'true') {
            setShowBrowserVerificationSuccessAlert(true);
            setTimeout(() => setShowBrowserVerificationSuccessAlert(false), 10000);
        }
    }, [location.search]);

  return (
    <GoogleReCaptchaProvider reCaptchaKey="6Lc1uyopAAAAANOL6i9Mi0q_sBDncbXU9Uoe1oLv">
      <ThemeProvider theme={defaultTheme}>
        <Box
          sx={{
            minHeight: '100vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            background: 'linear-gradient(135deg, #f5f7fa 0%, #e4e8f0 100%)',
            py: 4
          }}
        >
          <Container component="main" maxWidth="sm">
            <CssBaseline />
            <Paper
              elevation={3}
              sx={{
                p: 4,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                borderRadius: 3,
                boxShadow: '0 8px 32px rgba(0, 0, 0, 0.1)',
                background: 'white',
                overflow: 'hidden',
                position: 'relative',
                '&::before': {
                  content: '""',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  height: '4px',
                  background: 'linear-gradient(90deg, #3a7bd5 0%, #00b8d4 100%)',
                }
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <Box sx={{ mb: 3, textAlign: 'center' }}>
                  <img 
                    src='/Entrinsic_Logo.png' 
                    alt="Entrinsic Logo" 
                    style={{ 
                      height: '60px',
                      marginBottom: '16px'
                    }}
                  />
                  <Typography 
                    component="h1" 
                    variant="h4" 
                    sx={{ 
                      fontWeight: 600,
                      color: theme.palette.text.primary,
                      mb: 1
                    }}
                  >
                    Sign in
                  </Typography>
                  <Typography 
                    variant="body1" 
                    sx={{ 
                      color: theme.palette.text.secondary,
                      maxWidth: '400px',
                      mx: 'auto'
                    }}
                  >
                    Welcome back! Please enter your credentials to access your account.
                  </Typography>
                </Box>

                {isMobile && (
                  <Paper
                    elevation={2}
                    sx={{
                      p: 3,
                      mb: 4,
                      borderRadius: 2,
                      background: 'linear-gradient(to right, rgba(255,255,255,0.95), rgba(255,255,255,0.9))',
                      backdropFilter: 'blur(10px)',
                      border: '1px solid rgba(58, 123, 213, 0.2)',
                      boxShadow: '0 4px 20px rgba(0, 0, 0, 0.08)',
                      maxWidth: '100%'
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                      <Box
                        sx={{
                          mr: 2,
                          p: 1,
                          borderRadius: 1,
                          bgcolor: 'rgba(58, 123, 213, 0.1)',
                          color: theme.palette.primary.main,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        <InfoIcon />
                      </Box>
                      <Typography variant="h6" fontWeight="600" color={theme.palette.primary.main}>
                        Mobile Notice
                      </Typography>
                    </Box>
                    
                    <Typography variant="body1" paragraph sx={{ mb: 2 }}>
                      The web portal is not optimized for mobile devices. For the best experience, please use a desktop, laptop, or tablet computer.
                    </Typography>
                    
                    <Typography variant="body1" paragraph>
                      Looking for the Entrinsic Connect mobile app? Download it here:
                    </Typography>
                    
                    <Box sx={{ 
                      display: 'flex', 
                      justifyContent: 'center', 
                      gap: 2, 
                      flexWrap: 'wrap',
                      mt: 3
                    }}>
                      <Link href='https://apps.apple.com/us/app/entrinsic-connect/id6473429546?platform=iphone' target="_blank" rel="noopener">
                        <img 
                          src='/Appstore.png' 
                          alt='App Store' 
                          style={{ 
                            height: '48px', 
                            borderRadius: '8px',
                            boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
                            transition: 'transform 0.2s ease',
                            transform: 'scale(1)',
                            '&:hover': {
                              transform: 'scale(1.05)'
                            }
                          }} 
                        />
                      </Link>
                      <Link href='https://play.google.com/store/apps/details?id=io.Entrinsic.Connect&pcampaignid=web_share' target="_blank" rel="noopener">
                        <img 
                          src='/googleplay.png' 
                          alt='Google Play' 
                          style={{ 
                            height: '48px', 
                            borderRadius: '8px',
                            boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
                            transition: 'transform 0.2s ease',
                            transform: 'scale(1)',
                            '&:hover': {
                              transform: 'scale(1.05)'
                            }
                          }} 
                        />
                      </Link>
                    </Box>
                  </Paper>
                )}

                <LoginForm 
                  setShowNotVerifiedModal={setShowNotVerifiedModal}
                  setShowAlert={setShowAlert}
                  navigate={navigate}
                  setShowBrowserVerificationModal={setShowBrowserVerificationModal}
                />
              </Box>
            </Paper>
            <Copyright sx={{ mt: 4, mb: 2 }} />
          </Container>

          <Dialog
            open={showNotVerifiedModal}
            onClose={() => setShowNotVerifiedModal(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
              sx: {
                borderRadius: 3,
                boxShadow: '0 8px 32px rgba(0, 0, 0, 0.1)',
              }
            }}
          >
            <DialogTitle id="alert-dialog-title">Email Not Verified</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                You cannot use the system because you have not yet verified your email.
                Please check your inbox for the verification email.
              </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ p: 2 }}>
              <Button 
                onClick={handleResend} 
                color="primary"
                variant="contained"
                sx={{ 
                  borderRadius: 2,
                  px: 3
                }}
              >
                Resend Verification Email
              </Button>
            </DialogActions>
          </Dialog>

          <Snackbar 
            open={showAlert} 
            autoHideDuration={6000} 
            onClose={() => setShowAlert(false)}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          >
            <Alert 
              onClose={() => setShowAlert(false)} 
              severity="success" 
              variant="filled"
              sx={{ 
                borderRadius: 2,
                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)'
              }}
            >
              Verification email was resent!
            </Alert>
          </Snackbar>

          <Snackbar 
            open={showPasswordResetAlert} 
            autoHideDuration={15000}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          >
            <Alert 
              severity="success"
              sx={{ 
                borderRadius: 2,
                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)'
              }}
            >
              Your password reset link was sent - check your inbox.
            </Alert>
          </Snackbar>

          <Snackbar 
            open={showPasswordResetSuccessAlert} 
            autoHideDuration={15000}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          >
            <Alert 
              severity="success"
              sx={{ 
                borderRadius: 2,
                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)'
              }}
            >
              Your password has been successfully reset. Please log in with your new password.
            </Alert>
          </Snackbar>

          <Dialog
            open={showBrowserVerificationModal}
            onClose={() => setShowBrowserVerificationModal(false)}
            PaperProps={{
              sx: {
                borderRadius: 3,
                boxShadow: '0 8px 32px rgba(0, 0, 0, 0.1)',
              }
            }}
          >
            <DialogTitle>Browser Verification Required</DialogTitle>
            <DialogContent>
              <DialogContentText>
                For security purposes, we need to verify this browser. 
                Please check your email for a verification link.
              </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ p: 2 }}>
              <Button 
                onClick={() => setShowBrowserVerificationModal(false)}
                variant="contained"
                sx={{ 
                  borderRadius: 2,
                  px: 3
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>

          <Snackbar 
            open={showBrowserVerificationSuccessAlert} 
            autoHideDuration={10000} 
            onClose={() => setShowBrowserVerificationSuccessAlert(false)}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          >
            <Alert 
              severity="success" 
              variant="filled"
              sx={{ 
                borderRadius: 2,
                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)'
              }}
            >
              Browser verified successfully! You can now log in.
            </Alert>
          </Snackbar>
        </Box>
      </ThemeProvider>
    </GoogleReCaptchaProvider>
  );
}

