import React, { useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import { 
    Box, 
    Grid, 
    Card, 
    CardContent, 
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton,
    Tooltip
} from '@mui/material';
import ApiIcon from '@mui/icons-material/Api';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CodeBlock from '../CodeBlock';
import { tenants } from './tenants';
import { authentication } from './authentication';
import { numberPlates } from './numberPlates';
import { responderAliases } from './responderAliases';
import { pins } from './pins';
import { qrCodeKeys } from './qrCodeKeys';
import { logs } from './logs';
import { snapshots } from './snapshots';
import { movementVideos } from './movementVideos';
import { videoMessages } from './videoMessages';
import { settings } from './settings';
import { webhooks } from './webhooks';
import { kioskMessages } from './kioskMessages';
import { kioskStatus } from './kioskStatus';

const ApiDocumentation = ({ developerEmail }) => {
    const [openDialog, setOpenDialog] = useState(false);
    const [showCopySuccess, setShowCopySuccess] = useState(false);

    const generateAiPrompt = () => {
        const sections = [
            tenants,
            authentication(developerEmail),
            settings,
            numberPlates,
            pins,
            qrCodeKeys,
            responderAliases,
            logs,
            snapshots,
            movementVideos,
            videoMessages,
            kioskMessages,
            kioskStatus,
            webhooks
        ];

        // Convert the React elements to plain text
        const plainText = sections
            .map(section => {
                const div = document.createElement('div');
                div.innerHTML = ReactDOMServer.renderToString(section); 
                
                // Find all Typography elements and add newlines before and after them
                const typographyElements = div.getElementsByClassName('MuiTypography-root');
                Array.from(typographyElements).forEach(element => {
                    element.innerHTML = '\n' + element.innerHTML + '\n';
                });
                
                // Find all list items and add newlines
                const listItems = div.getElementsByTagName('li');
                Array.from(listItems).forEach(element => {
                    element.innerHTML = '\n• ' + element.innerHTML + '\n';
                });
                
                return div.textContent;
            })
            .join('\n\n');

        return `I am working with an API that has the following documentation:\n\n${plainText}\n\nPlease help me understand and work with this API.`;
    };

    const handleCopyToClipboard = () => {
        navigator.clipboard.writeText(generateAiPrompt());
        setShowCopySuccess(true);
        setTimeout(() => setShowCopySuccess(false), 2000);
    };

    return (
        <Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 3 }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <ApiIcon sx={{ mr: 1 }} />
                    <Typography variant="h6">
                        API Documentation
                    </Typography>
                </Box>
                <Tooltip title="Generate a comprehensive prompt containing all API documentation. You can paste this into ChatGPT or other AI assistants to get help with integration." arrow>
                    <Button
                        variant="outlined"
                        onClick={() => setOpenDialog(true)}
                        startIcon={<ApiIcon />}
                    >
                        Generate AI Prompt
                    </Button>
                </Tooltip>
            </Box>

            <Dialog
                open={openDialog}
                onClose={() => setOpenDialog(false)}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>AI-Ready Documentation</DialogTitle>
                <DialogContent>
                    <Box 
                        sx={{ 
                            backgroundColor: '#f5f5f5', 
                            p: 2, 
                            borderRadius: 1,
                            maxHeight: '300px',
                            overflowY: 'auto'
                        }}
                    >
                        <Typography variant="body2" sx={{ whiteSpace: 'pre-wrap', fontSize: '0.75rem' }}>
                            {generateAiPrompt()}
                        </Typography>
                    </Box>
                </DialogContent>
                <DialogActions sx={{ position: 'relative' }}>
                    <Button onClick={() => setOpenDialog(false)}>Close</Button>
                    <Button
                        onClick={handleCopyToClipboard}
                        startIcon={<ContentCopyIcon />}
                        variant="contained"
                    >
                        Copy to Clipboard
                    </Button>
                    {showCopySuccess && (
                        <Typography 
                            variant="caption" 
                            sx={{ 
                                position: 'absolute',
                                left: 16,
                                color: 'success.main'
                            }}
                        >
                            Copied!
                        </Typography>
                    )}
                </DialogActions>
            </Dialog>

            <Grid container spacing={3}>
                <Grid item xs={12}>
                    {tenants}
                </Grid>
                <Grid item xs={12}>
                   {authentication(developerEmail)}
                </Grid>
                <Grid item xs={12}>
                   {settings}
                </Grid>
                <Grid item xs={12}>
                   {numberPlates}
                </Grid>
                <Grid item xs={12}>
                    {pins}
                </Grid>
                <Grid item xs={12}>
                    {qrCodeKeys}
                </Grid>
                <Grid item xs={12}>
                    {responderAliases}
                </Grid>
                <Grid item xs={12}>
                    {logs}
                </Grid>
                <Grid item xs={12}>
                    {snapshots}
                </Grid>
                <Grid item xs={12}>
                    {movementVideos}
                </Grid>
                <Grid item xs={12}>
                    {videoMessages}
                </Grid>
                <Grid item xs={12}>
                    {kioskMessages}
                </Grid>
                <Grid item xs={12}>
                    {kioskStatus}
                </Grid>
                <Grid item xs={12}>
                    {webhooks}
                </Grid>
            </Grid>
        </Box>
    );
};

export default ApiDocumentation; 