import React, { useCallback, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import { Backdrop, CircularProgress, TextField } from '@mui/material';
import { Grid, Card, CardMedia, CardActions, Button, IconButton } from '@mui/material';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopy';

const FileUpload = ({ onFileUpload }) => {
    const onDrop = useCallback(acceptedFiles => {
        // Handle all files by iterating over them
        acceptedFiles.forEach(file => {
          onFileUpload(file);
        });
      }, [onFileUpload]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: true // Accept multiple files
  });

  return (
    <div {...getRootProps()} style={{ border: '1px solid black', padding: 20, textAlign: 'center' }}>
      <input {...getInputProps()} />
      {
        isDragActive ?
          <p>Drop the files here ...</p> :
          <p>Drag 'n' drop some files here, or click to select files</p>
      }
    </div>
  );
};

const ImageGallery = ({ onImageSelect, pickerMode, ...props }) => {
    const [images, setImages] = useState([]);
    const [selectedImageUrl, setSelectedImageUrl] = useState('');
    const [uploading, setUploading] = useState(false);
    const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
    const [imageToDelete, setImageToDelete] = useState(null);
    const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`/api/list-images/${props.tenantId}`);
        setImages(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching images:', error);
      }
    };
  
    fetchImages();
  }, [props.tenantId]);
  

  const uploadImage = async (file) => {
    setUploading(true); // Consider managing loading state per file if necessary

    const formData = new FormData();
    formData.append('file', file);
    formData.append('tenantId', props.tenantId);
    
    try {
      const response = await axios.post('/api/image-upload', formData);
      const data = response.data;
      setImages(prevImages => [...prevImages, data]);
    } catch (error) {
      alert('Error uploading file - only images are allowed.');
    } finally {
      setUploading(false); // Consider setting loading to false only after all files are uploaded
    }
  };
  
  const handleFileUpload = (file) => {
    uploadImage(file);
  };

  const handleImageClick = (imageUrl) => {
    if (pickerMode) {
      onImageSelect(imageUrl);
    } else {
      setSelectedImageUrl(imageUrl === selectedImageUrl ? '' : imageUrl);
    }
  };

  const handleOpenDeleteConfirm = (image) => {
    setImageToDelete(image);
    setOpenDeleteConfirm(true);
  };
  
  const handleCloseDeleteConfirm = () => {
    setOpenDeleteConfirm(false);
    setImageToDelete(null);
  };
  
  const handleConfirmDelete = async () => {
    if (imageToDelete) {
      handleCloseDeleteConfirm(); // Close the confirmation dialog
      setUploading(true); // Start loading before the deletion attempt
      try {
        console.log('Deleting image:', imageToDelete);
        await handleDelete(imageToDelete.url);
      } catch (error) {
        console.error('Error during deletion:', error);
      } finally {
        setUploading(false); // Stop loading after the deletion attempt
      }
    }
  };
  
  const handleDelete = async (url) => {
    const encodedPublicId = encodeURIComponent(url);
    try {
      await axios.delete(`/api/image-delete/${encodedPublicId}?tenantId=${props.tenantId}`);
      setImages(images.filter(image => image.url !== url));
    } catch (error) {
      console.error('Error deleting image:', error);
      setUploading(false); // Set loading false here if there's an error
    }
  };


  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(
        () => { console.log('Copied to clipboard'); },
        (err) => { console.error('Could not copy text: ', err); }
    );
  }
  
  
  return (
    <div>
      <FileUpload onFileUpload={handleFileUpload} />
      <Grid container spacing={2} style={{ marginTop: 20 }}>
        {loading ? (
                 <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                 <CircularProgress />
                 </Grid>
            ) :
            (<>

            {images.map(image => (
            <Grid item xs={6} sm={4} md={3} lg={2} key={image.public_id}>
                <Card
                elevation={image.url === selectedImageUrl ? 10 : 1} // Elevation added for selected image
                style={{
                border: image.url === selectedImageUrl ? '2px solid blue' : '', // Border color for selected image
                }}>
                <CardMedia
                    component="img"
                    image={image.url}
                    alt="Uploaded"
                    onClick={() => handleImageClick(image.url)}
                    style={{ transform: 'scale(0.5)',
                        height: 'auto', 
                        cursor: 'pointer', 
                        opacity: image.url === selectedImageUrl ? 0.7 : 1, // Dim the image if selected
                    }}

                />
                <CardActions>
                    <Button size="small" color="primary" onClick={() => handleOpenDeleteConfirm(image)}>
                    Delete
                    </Button>
                </CardActions>
                </Card>
            </Grid>
            ))}
        </>)}
      </Grid>
      {selectedImageUrl && (
        <Grid container style={{marginTop: 20}}>
            <Grid item xs={11}>
                <TextField
                    value={selectedImageUrl}
                    readOnly
                    fullWidth
                    style={{fontSize: '5px'}}
                />
            </Grid>
            <Grid item xs={1}>
                <IconButton aria-label="copy" size="small" onClick={() => copyToClipboard(selectedImageUrl || '')}>
                    <FileCopyIcon fontSize="small" />
                </IconButton>
            </Grid>
        </Grid>
      )}
      
      <Dialog
      open={openDeleteConfirm}
      onClose={handleCloseDeleteConfirm}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Confirm Deletion"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to delete this image?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDeleteConfirm} color="primary">
          Cancel
        </Button>
        <Button onClick={handleConfirmDelete} color="primary" autoFocus>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
    <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={uploading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default ImageGallery;
