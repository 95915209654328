import React, { useEffect, useState, useContext, useRef } from 'react';
import { 
  Container, Typography, Button, Paper, Snackbar, Alert, Box, Grid, 
  Divider, CircularProgress, IconButton, Tooltip, Card, CardContent, 
  CardActionArea, Chip, Avatar, useTheme, alpha
} from '@mui/material';
import Layout from '../components/Layout';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import axios from 'axios';
import SettingsIcon from '@mui/icons-material/Settings';
import PeopleIcon from '@mui/icons-material/People';
import LayersIcon from '@mui/icons-material/Layers';
import VideoChatIcon from '@mui/icons-material/VideoChat';
import LogsIcon from '@mui/icons-material/Topic';
import PreviewIcon from '@mui/icons-material/Preview';
import PinIcon from '@mui/icons-material/Pin';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import ContactsIcon from '@mui/icons-material/Contacts';
import UpgradeIcon from '@mui/icons-material/Upgrade';
import { AuthContext } from '../AuthContext';
import HelpIcon from '@mui/icons-material/Help';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import '../styles.css'; 
import { useNavigate, useLocation } from 'react-router-dom';
import { createIntroHighlights, removeIntroHighlights } from '../utils/introHighlight';
import QrCodeIcon from '@mui/icons-material/QrCode';
import CodeIcon from '@mui/icons-material/Code';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';

export default function HomePage() {
    const [data, setData] = React.useState(null);
    const [credentials, setCredentials] = React.useState('', '');
    const [showPassword, setShowPassword] = useState(false);
    const [keepalive, setKeepalive] = useState(null); 
    const { globalTenantId } = useContext(AuthContext);
    const [subscriptionPlan, setSubscriptionPlan] = useState(null);
    const globalTenantIdRef = useRef(globalTenantId);
    const [subscriptionInfoLoading, setSubscriptionInfoLoading] = useState(true);
    const [kioskStatusLoading, setKioskStatusLoading] = useState(true);
    const [kioskCredentialsLoading, setKioskCredentialsLoading] = useState(true); 
    const [showSuccess, setShowSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const { user } = useContext(AuthContext);
    const [showSubscriptionPanel, setShowSubscriptionPanel] = useState(false);
    const mobileBreakpoint = 600;
    const isMobile = window.innerWidth <= mobileBreakpoint;
    const theme = useTheme();

    useEffect(() => {
        globalTenantIdRef.current = globalTenantId;
    }, [globalTenantId]);
   
    // Callback to open a checkout
    const openCheckout = () => {
        navigate('/client-admin/upgrade'); 
    };

    const handlePreviewKiosk = () => {
        if (!globalTenantId) {
          console.log('No tenantid in context');
          return;
        }
  
        // show a popup with the kiosk in it
        let kioskUrl;
        axios.get('/api/setting?name=KIOSKURL')
        .then(result => {
            const data = result.data;
            kioskUrl = data.value + '?tenantId=' + globalTenantId;
            window.open(kioskUrl, 'kiosk', 'width=400,height=600');
          })
        .catch(error => console.error('Error fetching data:', error))
  
      };

    // Retrieve success message from the state
    useEffect(() => {
        if (location.state?.message) {
            setSuccessMessage(location.state.message);
            setShowSuccess(true);
        }
    }, [location]);

    const fetchPageData = () => {
        if (globalTenantId == null) return;
        
        setKioskStatusLoading(true);
        setKioskCredentialsLoading(true);
        axios.get(`/api/tenants/${globalTenantId}`, { params: { fields: ['OrganisationName'] } }).then(result => {
            setData(result.data);
        }).catch(error => {
            console.error('Error fetching data:', error)
        });
        axios.post('/api/kioskStatus', {}, { params: { tenantId: globalTenantId } }).then(result => {
            setKeepalive(result.data);
            setKioskStatusLoading(false);
          }).catch(error => {
            console.error('Error fetching kiosk status:', error);
          });
          
        axios.get('/api/kioskCredentials', { params: { tenantId: globalTenantId } }).then(result => {
            setCredentials(result.data);
            setKioskCredentialsLoading(false);
        }).catch(error => {
            console.error('Error fetching kiosk credentials:', error);
        });

        if (user.role == 'CLIENT_ADMIN') {
            setShowSubscriptionPanel(true);
            fetchSubscriptionData();
        }
    
    };

    const fetchSubscriptionData = () => {
        setSubscriptionInfoLoading(true);
        axios.get('/api/customer-subscription', { params: { tenantId: globalTenantIdRef.current } })
        .then(result => {
            const newPlan = JSON.parse(JSON.stringify(result.data));  // this must be done to force a re-render
            setSubscriptionPlan(newPlan);
            setSubscriptionInfoLoading(false);
        }).catch(error => {
            console.error('Error fetching customer sub:', error);
        });
    }

    useEffect(() => {
        fetchPageData();
    }, [globalTenantId]); 
    
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text).then(
            () => { console.log('Copied to clipboard'); },
            (err) => { console.error('Could not copy text: ', err); }
        );
    };

    const handleKioskHelp = () => {
        window.open('https://www.entrinsic.io/installation-guide', '_blank');
    };

    // Define menu items for the dashboard
    const menuItems = [
        { icon: <LogsIcon />, title: 'Activity Logs', description: 'View activity logs and device status', link: 'logs' },
        { icon: <LayersIcon />, title: 'Number Plates', description: 'Manage vehicle registration plates for ANPR access', link: 'number-plates' },
        { icon: <PinIcon />, title: 'PIN Codes', description: 'Configure PIN access codes', link: 'pins' },
        { icon: <QrCodeIcon />, title: 'QR Codes', description: 'Generate and manage QR code keys', link: 'qr-codes' },
        { icon: <VideoChatIcon />, title: 'Video Messages', description: 'Review visitor video messages', link: 'video-messages' },
        { icon: <PhotoLibraryIcon />, title: 'Snapshots', description: 'View captured snapshots and movement detection', link: 'snapshots' },
        { icon: <OndemandVideoIcon />, title: 'Movement Videos', description: 'View movement detection videos', link: 'movement-videos' },
        { icon: <PreviewIcon />, title: 'Preview Kiosk', description: 'Preview kiosk interface', link: '', onClick: handlePreviewKiosk },
        { icon: <SettingsIcon />, title: 'Settings', description: 'Configure kiosk settings and behavior', link: 'settings' },
        { icon: <CodeIcon />, title: 'API & Webhooks', description: 'Configure your developer account, webhooks and access API docs', link: 'api' },
    ];

    // Add admin-only menu items
    if (showSubscriptionPanel) {
        menuItems.push(
            { icon: <PeopleIcon />, title: 'User Management', description: 'Manage user access and responder permissions', link: 'users' },
            { icon: <ContactsIcon />, title: 'Responder Aliases', description: 'Configure aliases for responder selection and groupings', link: 'responder-aliases' }
        );
    }

    useEffect(() => {
        // Try to highlight after a short delay to ensure DOM is ready
        setTimeout(createIntroHighlights, 1000);

        // Cleanup
        return () => {
            removeIntroHighlights();
        };
    }, []);

    return (
        <Layout title={isMobile ? ' ' : 'Entrinsic Connect Dashboard'}>
            <Box sx={{ 
                animation: 'fadeIn 0.5s ease-in-out',
                '@keyframes fadeIn': {
                    '0%': {
                        opacity: 0,
                        transform: 'translateY(10px)',
                    },
                    '100%': {
                        opacity: 1,
                        transform: 'translateY(0)',
                    },
                },
            }}>
                <Box sx={{ 
                    mb: 4, 
                    p: 3, 
                    borderRadius: 2, 
                    background: `linear-gradient(135deg, ${alpha(theme.palette.primary.main, 0.1)} 0%, ${alpha(theme.palette.primary.light, 0.2)} 100%)`,
                    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.05)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}>
                    <Box>
                        <Typography variant="h5" fontWeight="600" color="primary.main">
                            {data ? data.OrganisationName : 'Loading...'}
                        </Typography>
                        {!isMobile && (
                            <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                                Select from the options below to manage and configure your device. Use the menu in the top right corner to switch between devices.
                            </Typography>
                        )}
                    </Box>
                    <Box sx={{ 
                        display: 'flex', 
                        alignItems: 'center', 
                        backgroundColor: 'background.paper', 
                        p: 1, 
                        borderRadius: 2,
                        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.08)'
                    }}>
                        {kioskStatusLoading ? (
                            <CircularProgress size={24} sx={{ mr: 1 }} />
                        ) : (
                            <>
                                {!keepalive || keepalive > 2 ? (
                                    <ErrorIcon color="error" sx={{ mr: 1 }} />
                                ) : (
                                    <CheckCircleIcon color="success" sx={{ mr: 1 }} />
                                )}
                            </>
                        )}
                        <Box>
                            <Typography variant="body2" fontWeight="500">
                                {kioskStatusLoading ? 'Checking status...' : 
                                    (!keepalive || keepalive > 2 ? 'Device Offline' : 'Device Online')}
                            </Typography>
                            {keepalive && !kioskStatusLoading && (
                                <Typography variant="caption" color="text.secondary">
                                    Last seen {keepalive.toFixed(1)} minute(s) ago
                                </Typography>
                            )}
                        </Box>
                    </Box>
                </Box>

                <Grid container spacing={3}>
                    {/* Main content area */}
                    <Grid item xs={12} lg={showSubscriptionPanel ? 8 : 12}>
                        {isMobile ? (
                            <Card sx={{ mb: 3, borderRadius: 2, boxShadow: '0 6px 18px rgba(0, 0, 0, 0.1)' }}>
                                <CardContent>
                                    <Typography variant="body1" sx={{ mb: 2 }}>
                                        The web portal is not optimised for mobile devices. Please use a desktop, laptop or tablet computer to access the full functionality.
                                    </Typography>
                                    <Typography variant="body1" sx={{ mb: 2 }}>
                                        If you are looking for the mobile app(s) you can download it from the following links:
                                    </Typography>
                                    
                                    <Box sx={{ 
                                        p: 2, 
                                        borderRadius: 2, 
                                        bgcolor: 'background.paper',
                                        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.08)'
                                    }}>
                                        <Typography variant="subtitle1" sx={{ 
                                            bgcolor: theme.palette.primary.main, 
                                            color: 'white', 
                                            p: 1, 
                                            borderRadius: 1, 
                                            mb: 2, 
                                            textAlign: 'center',
                                            fontWeight: 'bold'
                                        }}>
                                            MOBILE APPS
                                        </Typography>
                                        
                                        <Typography variant="subtitle2" sx={{ mb: 1, fontWeight: 'bold' }}>
                                            Entrinsic Connect (Responder)
                                        </Typography>
                                        <Box sx={{ display: 'flex', mb: 2 }}>
                                            <a href='https://apps.apple.com/us/app/entrinsic-connect/id6473429546?platform=iphone'>
                                                <img src='/Appstore.png' alt='App Store' width={100} style={{ marginRight: 8 }} />
                                            </a>
                                            <a href='https://play.google.com/store/apps/details?id=io.Entrinsic.Connect&pcampaignid=web_share'>
                                                <img src='/googleplay.png' alt='Google Play' width={100} />
                                            </a>
                                        </Box>
                                        
                                        <Typography variant="subtitle2" sx={{ mb: 1, fontWeight: 'bold' }}>
                                            Entrinsic Connect Kiosk
                                        </Typography>
                                        <Box sx={{ display: 'flex' }}>
                                            <a href='https://apps.apple.com/ph/app/entrinsic-connect-kiosk/id6473428401'>
                                                <img src='/Appstore.png' alt='App Store' width={100} style={{ marginRight: 8 }} />
                                            </a>
                                            <a href='https://play.google.com/store/apps/details?id=io.Entrinsic.Kiosk'>
                                                <img src='/googleplay.png' alt='Google Play' width={100} />
                                            </a>
                                        </Box>
                                    </Box>
                                </CardContent>
                            </Card>
                        ) : (
                            <>
                                {/* Dashboard menu grid */}
                                <Grid container spacing={2} sx={{ mb: 4 }}>
                                    {menuItems.map((item, index) => (
                                        <Grid item xs={12} sm={6} md={4} key={index}>
                                            <Card 
                                                sx={{ 
                                                    height: '100%',
                                                    textDecoration: 'none',
                                                    color: 'inherit',
                                                    cursor: 'pointer',
                                                    transition: 'all 0.2s ease',
                                                    '&:hover': {
                                                        transform: 'translateY(-4px)',
                                                        boxShadow: '0 12px 20px rgba(0, 0, 0, 0.1)',
                                                        '& .icon-wrapper': {
                                                            backgroundColor: theme.palette.primary.main,
                                                            color: 'white',
                                                        }
                                                    }
                                                }}
                                            >
                                                <CardActionArea 
                                                    component={item.onClick ? 'div' : 'a'}
                                                    href={item.onClick ? undefined : item.link}
                                                    onClick={item.onClick}
                                                    sx={{ 
                                                        height: '100%', 
                                                        p: 2,
                                                        display: 'block', // Ensure consistent display
                                                        textDecoration: 'none', // Remove underline for links
                                                        color: 'inherit' // Maintain text color
                                                    }}
                                                >
                                                    <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                                                        <Box 
                                                            className="icon-wrapper"
                                                            sx={{ 
                                                                mr: 2, 
                                                                p: 1.5, 
                                                                borderRadius: 2, 
                                                                backgroundColor: alpha(theme.palette.primary.main, 0.1),
                                                                color: theme.palette.primary.main,
                                                                transition: 'all 0.2s ease'
                                                            }}
                                                        >
                                                            {React.cloneElement(item.icon, { fontSize: 'medium' })}
                                                        </Box>
                                                        <Box>
                                                            <Typography variant="subtitle1" fontWeight="600" gutterBottom>
                                                                {item.title}
                                                            </Typography>
                                                            <Typography variant="body2" color="text.secondary">
                                                                {item.description}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                </CardActionArea>
                                            </Card>
                                        </Grid>
                                    ))}
                                </Grid>
                            </>
                        )}

                        {/* Kiosk login details card */}
                        <Card 
                            sx={{ 
                                mb: 3, 
                                borderRadius: 2, 
                                boxShadow: '0 6px 18px rgba(0, 0, 0, 0.1)',
                                overflow: 'visible'
                            }}
                            data-intro='Use these details to login to your device or kiosk. Click the help icon to find out more.'
                        >
                            <Box sx={{ 
                                p: 2, 
                                bgcolor: theme.palette.primary.main, 
                                color: 'white',
                                borderTopLeftRadius: 8,
                                borderTopRightRadius: 8,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between'
                            }}>
                                <Typography variant="subtitle1" fontWeight="bold">
                                    LIVE KIOSK LOGIN DETAILS
                                </Typography>
                                <Tooltip title='Click here to find out how to set up your device'>
                                    <IconButton 
                                        onClick={handleKioskHelp} 
                                        size="small" 
                                        sx={{ 
                                            color: 'white',
                                            bgcolor: 'rgba(255, 255, 255, 0.2)',
                                            '&:hover': {
                                                bgcolor: 'rgba(255, 255, 255, 0.3)',
                                            }
                                        }}
                                    >
                                        <HelpIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                            <CardContent sx={{ p: 3 }}>
                                {kioskCredentialsLoading ? (
                                    <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
                                        <CircularProgress size={30} />
                                    </Box>
                                ) : (
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <Box sx={{ 
                                                p: 2, 
                                                borderRadius: 2, 
                                                bgcolor: alpha(theme.palette.primary.light, 0.1),
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between'
                                            }}>
                                                <Box>
                                                    <Typography variant="caption" color="text.secondary">
                                                        Username
                                                    </Typography>
                                                    <Typography variant="body1" fontWeight="500">
                                                        {credentials.email || 'Loading...'}
                                                    </Typography>
                                                </Box>
                                                <IconButton 
                                                    onClick={() => copyToClipboard(credentials.email || '')}
                                                    size="small"
                                                    sx={{ 
                                                        bgcolor: alpha(theme.palette.primary.main, 0.1),
                                                        '&:hover': {
                                                            bgcolor: alpha(theme.palette.primary.main, 0.2),
                                                        }
                                                    }}
                                                >
                                                    <FileCopyIcon fontSize="small" />
                                                </IconButton>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Box sx={{ 
                                                p: 2, 
                                                borderRadius: 2, 
                                                bgcolor: alpha(theme.palette.primary.light, 0.1),
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between'
                                            }}>
                                                <Box>
                                                    <Typography variant="caption" color="text.secondary">
                                                        Password
                                                    </Typography>
                                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                        <Typography variant="body1" fontWeight="500" sx={{ mr: 1 }}>
                                                            {showPassword ? credentials.password : '••••••'}
                                                        </Typography>
                                                        <IconButton 
                                                            onClick={togglePasswordVisibility} 
                                                            size="small"
                                                            sx={{ mr: 1 }}
                                                        >
                                                            {showPassword ? <VisibilityOffIcon fontSize="small" /> : <VisibilityIcon fontSize="small" />}
                                                        </IconButton>
                                                    </Box>
                                                </Box>
                                                {showPassword && (
                                                    <IconButton 
                                                        onClick={() => copyToClipboard(credentials.password || '')}
                                                        size="small"
                                                        sx={{ 
                                                            bgcolor: alpha(theme.palette.primary.main, 0.1),
                                                            '&:hover': {
                                                                bgcolor: alpha(theme.palette.primary.main, 0.2),
                                                            }
                                                        }}
                                                    >
                                                        <FileCopyIcon fontSize="small" />
                                                    </IconButton>
                                                )}
                                            </Box>
                                        </Grid>
                                    </Grid>
                                )}
                            </CardContent>
                        </Card>
                    </Grid>

                    {/* Subscription panel */}
                    {showSubscriptionPanel && (
                        <Grid item xs={12} lg={4}>
                            <Card 
                                sx={{ 
                                    borderRadius: 2, 
                                    boxShadow: '0 6px 18px rgba(0, 0, 0, 0.1)',
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: 'column'
                                }}
                                data-intro='Here are the details of your account subscription (not specific to any one device). Click Upgrade to gain access to more advanced features of Entrinsic Connect.'
                            >
                                <Box sx={{ 
                                    p: 2, 
                                    bgcolor: theme.palette.primary.main, 
                                    color: 'white',
                                    borderTopLeftRadius: 8,
                                    borderTopRightRadius: 8
                                }}>
                                    <Typography variant="subtitle1" fontWeight="bold">
                                        SUBSCRIPTION PLAN
                                    </Typography>
                                </Box>
                                <CardContent sx={{ p: 3, flexGrow: 1 }}>
                                    {subscriptionInfoLoading ? (
                                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', flexDirection: 'column' }}>
                                            <Typography variant="body2" sx={{ mb: 2 }}>Loading subscription details...</Typography>
                                            <CircularProgress size={30} />
                                        </Box>
                                    ) : (
                                        <>
                                            <Chip 
                                                label={subscriptionPlan.plan.name}
                                                sx={{ 
                                                    width: '100%', 
                                                    mb: 3, 
                                                    p: 2, 
                                                    height: 'auto',
                                                    fontWeight: 'bold',
                                                    bgcolor: alpha(theme.palette.primary.main, 0.1),
                                                    color: theme.palette.primary.main,
                                                    '& .MuiChip-label': {
                                                        p: 1
                                                    }
                                                }}
                                            />
                                            
                                            <Grid container spacing={1.5}>
                                                <SubscriptionDetail label="Kiosks" value={subscriptionPlan.plan.NumberOfAllowedKiosks} />
                                                <SubscriptionDetail label="Responders" value={subscriptionPlan.plan.NumberOfAllowedResponders} />
                                                <SubscriptionDetail label="Movement Storage Days" value={subscriptionPlan.plan.MovementDetectionDaysStorage} />
                                                <SubscriptionDetail label="Movement Videos" value={subscriptionPlan.plan.MovementVideoAllowed ? 'Yes' : 'No'} />
                                                <SubscriptionDetail 
                                                    label="ANPR" 
                                                    value={subscriptionPlan.plan?.ANPRAllowedPlates > 0 ? 
                                                        `${subscriptionPlan.plan?.ANPRAllowedPlates} plates` : 'No'} 
                                                />
                                                <SubscriptionDetail 
                                                    label="QR Code Keys" 
                                                    value={subscriptionPlan.plan?.QRCodeKeysAllowed > 0 ? 
                                                        `${subscriptionPlan.plan?.QRCodeKeysAllowed} keys` : 'No'} 
                                                />
                                                <SubscriptionDetail 
                                                    label="Kiosk Customisation" 
                                                    value={subscriptionPlan.plan.AdvancedKioskCustomisation ? 'Yes' : 'No'} 
                                                />
                                                <SubscriptionDetail 
                                                    label="Gate Opening" 
                                                    value={subscriptionPlan.plan.GateOpening ? 'Yes' : 'No'} 
                                                />
                                                <SubscriptionDetail 
                                                    label="Log Days Storage" 
                                                    value={subscriptionPlan.plan.LogDaysStorage} 
                                                />
                                                <SubscriptionDetail 
                                                    label="Aliases (multi tenant)" 
                                                    value={subscriptionPlan.plan.NumberOfAllowedAliases} 
                                                />
                                            </Grid>
                                            
                                            {subscriptionPlan.paddleData.items && (
                                                <>
                                                    <Divider sx={{ my: 3 }} />
                                                    
                                                    <Chip 
                                                        label="Billing Information"
                                                        sx={{ 
                                                            width: '100%', 
                                                            mb: 3, 
                                                            p: 2, 
                                                            height: 'auto',
                                                            fontWeight: 'bold',
                                                            bgcolor: alpha(theme.palette.primary.main, 0.1),
                                                            color: theme.palette.primary.main,
                                                            '& .MuiChip-label': {
                                                                p: 1
                                                            }
                                                        }}
                                                    />
                                                    
                                                    <Typography variant="body2" sx={{ mb: 2 }}>
                                                        {subscriptionPlan.paddleData.items[0].price.description}
                                                    </Typography>
                                                    
                                                    <Grid container spacing={1.5}>
                                                        <SubscriptionDetail 
                                                            label="Price" 
                                                            value={`${subscriptionPlan.paddleData.recurring_transaction_details.totals.currency_code} ${subscriptionPlan.paddleData.recurring_transaction_details.totals.total / 100}`} 
                                                        />
                                                        <SubscriptionDetail 
                                                            label="Next billed" 
                                                            value={(() => {
                                                                if (subscriptionPlan && subscriptionPlan.paddleData && 
                                                                    subscriptionPlan.paddleData.items && 
                                                                    subscriptionPlan.paddleData.items.length > 0) {
                                                                    const nextBilledDate = new Date(subscriptionPlan.paddleData.items[0].next_billed_at);
                                                                    return nextBilledDate.toLocaleDateString();
                                                                }
                                                                return '';
                                                            })()} 
                                                        />
                                                    </Grid>
                                                </>
                                            )}
                                            
                                            {!user.partnerId && (
                                                <Button 
                                                    variant="contained" 
                                                    color="primary" 
                                                    onClick={openCheckout} 
                                                    startIcon={<UpgradeIcon />}
                                                    sx={{ 
                                                        mt: 3,
                                                        py: 1.5,
                                                        fontWeight: 'bold',
                                                        mt: 3,
                                                        py: 1.5,
                                                        fontWeight: 'bold',
                                                        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
                                                        '&:hover': {
                                                            boxShadow: '0 6px 16px rgba(0, 0, 0, 0.2)',
                                                        }
                                                    }}
                                                >
                                                    {subscriptionPlan.paddleData.items ? 'CHANGE OR UPGRADE' : 'UPGRADE'}
                                                </Button>
                                            )}
                                            
                                            {user.is_partner_user && (
                                                <Typography 
                                                    variant='body2' 
                                                    sx={{ 
                                                        mt: 3, 
                                                        p: 2, 
                                                        borderRadius: 1, 
                                                        bgcolor: alpha(theme.palette.info.light, 0.1),
                                                        color: theme.palette.info.dark
                                                    }}
                                                >
                                                    To change your customer's subscription level please contact Entrinsic Connect Support
                                                </Typography>
                                            )}
                                            
                                            {user.partnerId && !user.is_partner_user && (
                                                <Typography 
                                                    variant='body2' 
                                                    sx={{ 
                                                        mt: 3, 
                                                        p: 2, 
                                                        borderRadius: 1, 
                                                        bgcolor: alpha(theme.palette.info.light, 0.1),
                                                        color: theme.palette.info.dark
                                                    }}
                                                >
                                                    To change your subscription level please contact {user.partnerName}
                                                </Typography>
                                            )}
                                        </>
                                    )}
                                </CardContent>
                            </Card>
                        </Grid>
                    )}
                </Grid>
            </Box>
            
            {/* Success message snackbar */}
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                open={showSuccess}
                autoHideDuration={5000}
                onClose={() => setShowSuccess(false)}
            >
                <Alert 
                    severity="success" 
                    onClose={() => setShowSuccess(false)}
                    sx={{ 
                        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
                        borderRadius: 2
                    }}
                >
                    {successMessage}
                </Alert>
            </Snackbar>
        </Layout>
    );
}

// Helper component for subscription details
const SubscriptionDetail = ({ label, value }) => {
    const theme = useTheme();
    
    return (
        <>
            <Grid item xs={7}>
                <Typography variant="body2" fontWeight="500" color="text.primary">
                    {label}:
                </Typography>
            </Grid>
            <Grid item xs={5}>
                <Typography 
                    variant="body2" 
                    sx={{ 
                        bgcolor: alpha(theme.palette.background.default, 0.6),
                        p: 0.5,
                        borderRadius: 1,
                        textAlign: 'center'
                    }}
                >
                    {value}
                </Typography>
            </Grid>
        </>
    );
};