import React, { useState, useEffect, useContext } from 'react';
import { TextField, Button, Checkbox, Accordion, AccordionSummary, AccordionDetails, Slider, Select, MenuItem, FormControl, RadioGroup, Radio, InputLabel, FormControlLabel, Tabs, Tab, Container, Grid, Box, CircularProgress, Tooltip, Dialog, DialogTitle, DialogContent, DialogActions, Typography, Paper, Divider, Switch } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Layout from '../../components/Layout';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from 'axios';
import PropTypes from 'prop-types';
import { AuthContext } from '../../AuthContext';
import ImageGallery from '../../components/Settings/ImageGallery';
import WarningIcon from '@mui/icons-material/Warning';
import ThemeGallery from '../../components/Settings/ThemeGallery';
import { useNavigate } from 'react-router-dom';
import ReactDiffViewer from 'react-diff-viewer-continued';
import GateOpeningTimes from '../../components/Settings/GateOpeningTimes';

// Add this constant at the top of the file, outside the component
const DEFAULT_GATE_SCHEDULE = {
    monday: { enabled: false, start: '', end: '' },
    tuesday: { enabled: false, start: '', end: '' },
    wednesday: { enabled: false, start: '', end: '' },
    thursday: { enabled: false, start: '', end: '' },
    friday: { enabled: false, start: '', end: '' },
    saturday: { enabled: false, start: '', end: '' },
    sunday: { enabled: false, start: '', end: '' }
};

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }


  
const SettingsPage = () => {
    
  const [settings, setSettings] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isModified, setIsModified] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openWarningDialog, setOpenWarningDialog] = useState(false);
  const [serverKioskUI, setServerKioskUI] = useState(null); // new state to hold server default Kiosk UI
  const [openRevertWarningDialog, setOpenRevertWarningDialog] = useState(false);
  const { globalTenantId } = useContext(AuthContext);
  const [value, setValue] = React.useState(0);
  const [selectedKiosk, setSelectedKiosk] = React.useState(null);
  const [kiosks, setKiosks] = useState([]);
  const [kioskTypes, setKioskTypes] = useState([]);
  const [kioskThemes, setKioskThemes] = useState([]);
  const [showThemeDialog, setShowThemeDialog] = useState(false);
  const [customKioskThemeID, setCustomKioskThemeID] = useState(null);
  const [showResetBluetoothDialog, setShowResetBluetoothDialog] = useState(false);
  const [bluetoothResetOption, setBluetoothResetOption] = useState('basic');
  const [bluetoothCustomPasscode, setBluetoothCustomPasscode] = useState(null);
  const [disableNumberPlateAliases, setDisableNumberPlateAliases] = useState(false);
  const [disablePINAliases, setDisablePINAliases] = useState(false);
  const [masterPlateAliases, setMasterPlateAliases] = useState(null);
  const [masterPINAliases, setMasterPINAliases] = useState(null);
  const [masterTenantNameForNumberPlates, setMasterTenantNameForNumberPlates] = useState(null);
  const [masterTenantNameForPINs, setMasterTenantNameForPINs] = useState(null);
  const [masterTenantNameForQrCodeKeys, setMasterTenantNameForQrCodeKeys] = useState(null);
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const [isAccordionExpanded, setIsAccordionExpanded] = useState(false);
  const [previousCustomKioskThemeID, setPreviousCustomKioskThemeID] = useState(null);
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const [disableQrCodeKeysAliases, setDisableQrCodeKeysAliases] = useState(false);
  const [masterQrCodeKeyAliases, setMasterQrCodeKeyAliases] = useState(null);
  const [showDiffDialog, setShowDiffDialog] = useState(false);
  const [gateSchedule, setGateSchedule] = useState(DEFAULT_GATE_SCHEDULE);
  const [gateTimesDialogOpen, setGateTimesDialogOpen] = useState(false);
  // Add effect to reset isModified when globalTenantId changes
  useEffect(() => {
    setIsModified(false);
  }, [globalTenantId]);

  const handleNavigationAttempt = (checkOnly = false) => {
    if (!isModified) return true;
    
    if (checkOnly) {
        // Just return false if we're only checking for changes
        return false;
    }
    
    // Show confirmation dialog only if we're not in check-only mode
    return window.confirm('You have unsaved changes. Do you want to leave this page? Your changes will be lost.');
};

  const handleAccordionToggle = () => {
      setIsAccordionExpanded(!isAccordionExpanded);
  };

  const handleOpenGallery = () => {
      setIsGalleryOpen(true);
  };

  const handleCloseGallery = () => {
      setIsGalleryOpen(false);
  };

  const handleImageSelect = (url) => {
    setIsModified(true);
    setSettings(prevSettings => ({
        ...prevSettings,
        SettingCustomKioskThemeBackgroundImageURL: url
    }));
    handleCloseGallery();
    };

    const handleLandscapeImageSelect = (url) => {
        setIsModified(true);
        setSettings(prevSettings => ({
            ...prevSettings,
            SettingCustomKioskThemeLandscapeBackgroundImageURL: url
        }));
        handleCloseGallery();
        };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    let newValue;

    if (type === 'checkbox') {
        newValue = checked;
        // Reset schedule when gate opening restrictions are disabled
        if (name === 'SettingGateOpeningRestrictions' && !checked) {
            setGateSchedule(DEFAULT_GATE_SCHEDULE);
        }
    } else if (name === 'securityLevel') {
        // Special handling for security level to allow empty value
        newValue = value === '' ? null : value;
    } else {
        newValue = value;
    }

    setSettings(prevSettings => ({
        ...prevSettings,
        [name]: newValue
    }));
    setIsModified(true);
  };
  

  const handleSave = (e) => {
    e.preventDefault();
    
    // check BT password
    if (!settings.SettingAllowBluetoothRelay && settings.BluetoothPasscode && 
        !window.confirm("You are unsetting the Bluetooth relay option, but have a Bluetooth passcode set. " + 
        `If you continue, you will not be able to reuse the relay without the current passcode, which is ${settings.BluetoothPasscode}. You must take ` + 
        `note of this passcode in case you want to reset the relay in the future. Are you sure you want to continue?`
        ))
    {
        return;
    }

    setLoading(true);

    // remove bluetoothpasscode from settings
    // Destructure the properties we don't want to send
    const { BluetoothPasscode, ResetBluetoothCustomPasscode, ResetBluetooth, KioskUniqueLoginToken, ...settingsToSend } = settings;
    
    // if SettingAllowBluetoothRelay is off, reset the bluetoothpasscode and resetbluetoothcustompasscode
    if (!settings.SettingAllowBluetoothRelay)
    {
        settings.BluetoothPasscode = null; // also set in the 'local' settings to update UI
        settingsToSend.BluetoothPasscode = null;
        settingsToSend.ResetBluetoothCustomPasscode = null;
    }

    axios.put(`/api/tenants/${settings.id}`, settingsToSend)
      .then(
        () => {
            // request kiosk refresh
            axios.post(`/api/refreshKioskUI`, {tenantId: globalTenantId, room: 'Room'}).then(result => {
                console.log('Kiosk UI refresh request sent');
            }).catch(error => { 
                console.error('Error sending Kiosk UI refresh request:', error);
            });
            setLoading(false);
            setIsModified(false);
        })
        .catch(error => {
            setLoading(false);
            if (error.response && error.response.data && error.response.data.message) {
                alert(error.response.data.message);
            } else {
                console.error('Error saving data:', error);
                alert('An unexpected error occurred.');
            }
        });
        
  };

  const handleUnload = (e) => {
        if (isModified) {
            // For browser/tab close only, not for internal navigation
            const message = 'You have unsaved changes. Are you sure you want to leave?';
            e.preventDefault();
            e.returnValue = message;
            return message;
        }
    };

  const setServerKioskUIFromAPI = async (forceKioskTypeId) => {
    let apiUrl = `/api/kioskUI?tenantId=${globalTenantId}`
    if (forceKioskTypeId)
    {
        apiUrl += `&forceKioskTypeId=${forceKioskTypeId}`   
    }
    try
    {
        let result = await axios.get(apiUrl);
        //console.log(result.data.kioskUI)
        setServerKioskUI(result.data.kioskUI);
        return result.data.kioskUI;
      
    }
    catch(error) {
        console.error('Error fetching default Kiosk UI:', error);
      
    }
  }

  useEffect(() => {
    if (globalTenantId == null) return;
    window.addEventListener('beforeunload', handleUnload);
    
    // Reset schedule to default when tenant changes
    setGateSchedule(DEFAULT_GATE_SCHEDULE);

    axios.get('/api/tenants', { params: { tenantId: globalTenantId } })
      .then(result => {
        setLoading(false);
        setSettings(result.data);
        
        // Parse gate schedule if it exists and restrictions are enabled
        if (result.data.SettingGateOpeningRestrictions && result.data.SettingGateOpeningSchedule) {
            try {
                const schedule = JSON.parse(result.data.SettingGateOpeningSchedule);
                setGateSchedule(schedule);
            } catch (e) {
                console.error('Error parsing gate schedule:', e);
                setGateSchedule(DEFAULT_GATE_SCHEDULE);
            }
        } else {
            // Reset to default if restrictions are disabled or no schedule exists
            setGateSchedule(DEFAULT_GATE_SCHEDULE);
        }

        // check sharing of plates & pins
        axios.get(`/api/number-plates/is-shared?tenantId=${globalTenantId}`)
            .then(result => {
                // disable checkbox if sharing is enabled and the SharingPlatesMasterTenantID is not the current tenant
                if (result.data.SharePlates && result.data.SharingPlatesMasterTenantID != globalTenantId)
                {
                    setDisableNumberPlateAliases(true);
                    setMasterTenantNameForNumberPlates(result.data.masterTenantName);
                    setMasterPlateAliases(result.data.masterPlateAliases);
                }
                else
                {
                    setMasterPlateAliases(null);
                    setDisableNumberPlateAliases(false);
                }
            }).catch(error => {
                console.error('Error fetching sharing of plates:', error);
            }
        );
        axios.get(`/api/pins/is-shared?tenantId=${globalTenantId}`)
            .then(result => {
                // disable checkbox if sharing is enabled and the SharingPlatesMasterTenantID is not the current tenant
                if (result.data.SharePINs && result.data.SharingPINsMasterTenantID != globalTenantId)
                {
                    setDisablePINAliases(true);
                    setMasterTenantNameForPINs(result.data.masterTenantName);
                    setMasterPINAliases(result.data.masterPINAliases);
                }
                else
                {
                    setMasterPINAliases(null);
                    setDisablePINAliases(false);
                }
            }).catch(error => {
                console.error('Error fetching sharing of plates:', error);
            }
        );
        axios.get(`/api/qr-code-keys/is-shared?tenantId=${globalTenantId}`)
            .then(result => {
                // disable checkbox if sharing is enabled and the SharingPlatesMasterTenantID is not the current tenant
                if (result.data.ShareQrCodeKeys && result.data.SharingQrCodeKeysMasterTenantID != globalTenantId)
                {
                    setDisableQrCodeKeysAliases(true);
                    setMasterTenantNameForQrCodeKeys(result.data.masterTenantName);
                    setMasterQrCodeKeyAliases(result.data.masterQrCodeKeyAliases);
                }
                else
                {
                    setMasterQrCodeKeyAliases(null);
                    setDisableQrCodeKeysAliases(false);
                }
            }).catch(error => {
                console.error('Error fetching sharing of plates:', error);
            }
        );

       
      })
      .catch(error => {
        setLoading(false);
        console.error('Error fetching data:', error);
      });

      // populate kiosks dropdown
      axios.get('/api/kiosks')
      .then(response => {
        if (response.data.length === 0) {
          // no access to kiosks - log out
          throw new Error('No kiosks available');
        }
        // remove current kiosk from list
        response.data = response.data.filter(kiosk => kiosk.id !== globalTenantId);

          // add 'None' option
          response.data.unshift({id: 'none', OrganisationName: 'None'});

          // populate dropdown
          setKiosks(response.data);
                  
        })
        .catch(error => {
          console.error('Error fetching kiosks:', error);
          // Additional error handling logic here
        });

        // populate kiosk types dropdown
        axios.get('/api/kiosk-types')
        .then(response => {
          if (response.data.length === 0) {
            // no access to kiosks - log out
            throw new Error('No kiosk types available');
          }
          // populate dropdown
          setKioskTypes(response.data);
                  
        })
        .catch(error => {
          console.error('Error fetching kiosk types:', error);
          // Additional error handling logic here
        });

         // populate kiosk themes dropdown
         axios.get('/api/kiosk-themes')
         .then(response => {
           if (response.data.length === 0) {
             // no access to kiosks - log out
             throw new Error('No kiosk themes available');
           }
           // populate dropdown
           setKioskThemes(response.data);
                   
         })
         .catch(error => {
           console.error('Error fetching kiosk themes:', error);
           // Additional error handling logic here
         });

         // fetch server kiosk UI
         setServerKioskUIFromAPI();

    return () => {
        console.log('removing handleunload')
        window.removeEventListener('beforeunload', handleUnload);
    }
  }, [globalTenantId]);

  const confirmRevertToOriginal = async () => {
    console.log('reverting...')
    let serverData = await setServerKioskUIFromAPI();
    setSettings(prevSettings => ({
      ...prevSettings,
      SettingKioskUI: serverData
    }));
    setIsModified(true);
    setOpenRevertWarningDialog(false);  
  };

  const handleCustomKioskUIChange = (e) => {
    const checked = e.target.checked;
    if (checked) {
      // Show the warning dialog if the box is checked
      setOpenWarningDialog(true);
    } else {
      handleInputChange(e);
    }
  };

  const handleRevertToOriginal = () => {
    // Open the revert warning dialog
    setOpenRevertWarningDialog(true);
  };

  const  updateKioskUI = async (e) => {
    console.log('updating kiosk UI')
    let serverData = await setServerKioskUIFromAPI(e.target.value);
    console.log('reverting...')
    setSettings(prevSettings => ({
      ...prevSettings,
      SettingKioskUI: serverData
    }));
    setIsModified(true);
    setOpenRevertWarningDialog(false);  
  }

  const handleCustomTheme = () => {
    setShowThemeDialog(false);

    // lookup the custom theme CSS from api/kiosk-themes
    axios.get(`/api/kiosk-themes?id=${kioskThemes.find(theme => theme.id === customKioskThemeID).id}`)
    .then(response => {
        if (response.data.length === 0) {
        // no access to kiosks - log out
        throw new Error('No kiosk themes available');
        }
        console.log(response.data);
        setSettings(prevSettings => ({
            ...prevSettings,
            SettingCustomKioskTheme: response.data.css,
            SettingCustomKioskThemeBackgroundImageURL: response.data.BackgroundImageURL,
            SettingCustomKioskThemeLandscapeBackgroundImageURL: response.data.LandscapeBackgroundImageURL,
            SettingCustomKioskThemeColour1: response.data.Colour1,
            SettingCustomKioskThemeColour2: response.data.Colour2,
            SettingCustomKioskThemeColour3: response.data.Colour3,
            SettingCustomKioskThemeColour4: response.data.Colour4,
            SettingCustomKioskThemeColour5: response.data.Colour5,
            SettingCustomKioskThemeColour6: response.data.Colour6,
        }));
        setIsModified(true);
    }).catch(error => {
        console.error('Error fetching kiosk themes:', error);

    });
    }
    const handleCustomThemeCancel = () => {
        setShowThemeDialog(false);
        // if there is no custom CSS (settings.SettingCustomKioskTheme) then switch back to previously selected theme
        if (!settings.SettingCustomKioskTheme)
        {
            console.log('setting back to ' + previousCustomKioskThemeID);
            // revert to previous theme
            setSettings(prevSettings => ({
                ...prevSettings,
                KioskThemeID: previousCustomKioskThemeID
            }));
        }
    }

    const resetCustomTheme = () => {

        // add are you sure
        if (!window.confirm('Are you sure you want to reset the custom theme? You will lose any customisations made to the theme.')) return;

        setShowThemeDialog(true);
    }

    const showBlueToothDialog = () => {
        // fetch the current passcode
        
        axios.get(`/api/tenants-bluetoothpasscode?tenantId=${globalTenantId}`)
        .then(response => {
            if (response.data.length === 0) {
            // no access to kiosks - log out
            throw new Error('Could not get passcode');
            }
            
            // set passcode in settings
            setSettings(prevSettings => ({
                ...prevSettings,
                ResetBluetooth: response.data.ResetBluetooth,
                BluetoothPasscode: response.data.passcode
            }));
            setBluetoothResetOption(response.data.passcode ? 'basic' : 'reset');
            setShowResetBluetoothDialog(true);
        }).catch(error => {
            console.error('Error fetching kiosk themes:', error);
        });
        
    }
    const confirmResetBluetooth = () => {

        if (bluetoothResetOption != 'cancel' && !window.confirm('Are you sure you want to reset the bluetooth device? You will need to forcibly reset and reload the app on the Kiosk to enter the Bluetooth setup screen.')) return;

        axios.post(`/api/tenants-resetbluetooth`, {tenantId: globalTenantId, resetType: bluetoothResetOption, passcode: bluetoothCustomPasscode})
        .then(result => {
            setShowResetBluetoothDialog(false);
            console.log('Bluetooth reset request sent');
        }).catch(error => { 
            console.error('Error sending Bluetooth reset request:', error);
        });
    }

     // Return null or a loading indicator if settings is not yet available
  if (!settings) {
    return (<Layout title='Settings'><CircularProgress /></Layout>);
  }

  // Add this line before your JSX return statement
  const foundTheme = kioskThemes.find(theme => theme.id === settings.KioskThemeID);

  const handleScheduleChange = (day, field, value) => {
      setIsModified(true);
      const newSchedule = {
          ...gateSchedule,
          [day]: {
              ...gateSchedule[day],
              [field]: value
          }
      };
      setGateSchedule(newSchedule);
      setSettings(prev => ({
          ...prev,
          SettingGateOpeningSchedule: JSON.stringify(newSchedule)
      }));
  };

  return (
    <Layout 
        title='Settings'
        onNavigate={handleNavigationAttempt}
    >
      <Container>
          <form onSubmit={handleSave}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" variant='scrollable'>
                    <Tab label="Basic Details" {...a11yProps(0)} />
                    <Tab label="Kiosk Settings" {...a11yProps(1)} />
                    <Tab label="Gate Opening" {...a11yProps(2)} />
                    <Tab label="Kiosk Style" {...a11yProps(3)} />
                    <Tab label="Kiosk UI" {...a11yProps(4)} />
                    <Tab label="Custom Images" {...a11yProps(5)} />
                    <Tab label="ANPR" {...a11yProps(6)} />
                    <Tab label="QR Code Keys" {...a11yProps(7)} />
                </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <TextField fullWidth label="Kiosk Name" name="OrganisationName" value={settings.OrganisationName || ''} onChange={handleInputChange} variant="outlined" />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField fullWidth label="Title Text" name="SettingTitleText" value={settings.SettingTitleText || ''} onChange={handleInputChange} variant="outlined" />
                    </Grid>
                    <Grid item xs={6}>
                    <Tooltip title="The behaviour of the device. Changing this will reset any customisations made on the kiosk.">
                            <FormControl variant="filled" fullWidth='true'>
                                <InputLabel id="kiosk-label">Device type</InputLabel>
                                <Select
                                    value={settings.KioskTypeID}
                                    onChange={(e)=>{
                                        if (settings.SettingCustomKioskUI)
                                        {
                                            alert('You cannot change this because you have customised the UI. Unset the custom kiosk UI option first before changing this.');
                                            return;
                                        }
                                        handleInputChange(e);
                                        updateKioskUI(e);
                                        }
                                    }
                                    label="Device type"
                                    labelId="devicetype_label"
                                    name="KioskTypeID"
                                    >
                                    {kioskTypes.map((kioskType) => (
                                        <MenuItem key={kioskType.id} value={kioskType.id}>
                                        {kioskType.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography>The types of device available are:
                            <ul>
                                <li><strong>Visitor entry kiosk</strong> - presenting a user interface for visitors to request access to your property or land.</li>
                                <li><strong>Assistance</strong> - users can click a button to request assistance. Use cases include bedside care, kiosk support and general assistance.</li>
                                <li><strong>Dedicated ANPR</strong> - there is no user interface; the device acts only as an ANPR camera, CCTV device and proactive communicator.</li>
                                <li><strong>Non-interactive</strong> - there is no user interface; the device acts only as a CCTV and/or proactive communicator. Typical use case - CCTV, pet or baby monitor.</li>
                                <li><strong>PIN pad</strong> - the device acts only as a PIN pad, CCTV device and proactive communicator. Typical use case is property/land exit keypad.</li>
                            </ul>
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Tooltip title="Shows the Visitor option (button) in the Kiosk">
                            <FormControlLabel
                                control={<Checkbox name="SettingIncludeVisitorButton" checked={settings.SettingIncludeVisitorButton || false} onChange={handleInputChange} />}
                                label="Include Visitor Button"
                            />
                        </Tooltip>
                    </Grid>
                </Grid>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                <Typography style={{fontWeight: 'bold', paddingBottom: 20}}>Movement Detection &amp; Snapshots</Typography>
                <Grid container spacing={3}>
                    <Grid item xs={3}>
                        <Tooltip title="Enable the device to detect movement, take a snapshot, send a notification, and optionally record video">
                            <FormControlLabel
                                control={<Checkbox name="SettingEnableMovementDetection" checked={settings.SettingEnableMovementDetection || false} onChange={handleInputChange} />}
                                label="Enable movement detection"
                            />
                        </Tooltip>
                    </Grid>
                    <Grid item xs={3}>
                            <Tooltip title="The number of seconds to wait until another movement notification is sent.">
                                <TextField disabled={!settings.SettingEnableMovementDetection} fullWidth label="Movement Waiting (seconds)"
                                    name="SettingMovementNotificationWaitSeconds"
                                    value={settings.SettingMovementNotificationWaitSeconds}
                                    onChange={handleInputChange} variant="outlined"

                                    />
                            </Tooltip>
                    </Grid>
                    <Grid item xs={3}>
                            <Tooltip title="You can adjust the sensitivity of the movement detection captures and notifications to reduce the amount of network traffic. The lower the sensitivity, the more movement is
                            required to trigger a snapshot. The higher it is, the more snapshots will be taken, and the more network traffic will
                            be generated and data storage required.">
                                <Box sx={{ m: 0 }} />
                                <Typography gutterBottom>Movement sensitivity</Typography>
                                <Slider name="SettingMovementSensitivity"
                                    disabled={!settings.SettingEnableMovementDetection}
                                    value={settings.SettingMovementSensitivity || ''}
                                    onChange={handleInputChange}
                                    min={5000000} max={30000000}
                                />
                            </Tooltip>
                    </Grid>
                    <Grid item xs={3}>
                        <Tooltip title="Allow movement videos to be captured">
                            <FormControlLabel
                                control={<Checkbox name="SettingEnableMovementVideo" 
                                disabled={!settings.SettingEnableMovementDetection}
                                checked={settings.SettingEnableMovementVideo || false} onChange={handleInputChange} />}
                                label="Enable movement video"
                            />
                        </Tooltip>
                    </Grid>
                    <Grid item xs={3}>
                        <Tooltip title="Allow movement snapshots to be taken on a schedule">
                            <FormControlLabel
                                control={<Checkbox name="SettingEnableSnapshots" checked={settings.SettingEnableSnapshots || false} onChange={handleInputChange} />}
                                label="Enable scheduled snapshots"
                            />
                        </Tooltip>
                    </Grid>
                    <Grid item xs={3}>
                        <Tooltip title="Enter the frequency in seconds that snapshots will be taken">
                            <TextField disabled={!settings.SettingEnableSnapshots} fullWidth label="Seconds between snapshots" name="SettingSnapshotFrequencySeconds" value={settings.SettingSnapshotFrequencySeconds} onChange={handleInputChange} variant="outlined" 
                            />
                        </Tooltip>
                    </Grid>

                    <Grid item xs={12}>
                        <Divider/>
                        <Typography style={{fontWeight: 'bold', paddingBottom: 0, paddingTop: 20}}>Kiosk Behaviour</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Tooltip title="Tick this box if you want visitors/users to be able to choose a specific responder. You can set up aliases for one or more responders from the Aliases menu.">
                            <FormControlLabel
                                control={<Checkbox name="SettingAllowKioskResponderChoice" checked={settings.SettingAllowKioskResponderChoice || false} onChange={handleInputChange} />}
                                label="Allow user to select specific responder (alias)"
                            />
                        </Tooltip>
                    </Grid>
                
                    <Grid item xs={6}>
                        <Tooltip title="Tick this box if you want visitors/users to be able to choose to notify all responders (only relevant if allowing specific responder choice).">
                            <FormControlLabel
                                control={<Checkbox name="SettingAllowKioskAllResponderChoice" checked={settings.SettingAllowKioskAllResponderChoice || false} onChange={handleInputChange} />}
                                disabled={!settings.SettingAllowKioskResponderChoice}
                                label="Allow user to choose to notify all responders"
                            />
                        </Tooltip>
                    </Grid>
                    <Grid item xs={6}>
                        <Tooltip title="Use this setting to ensure all gate open notifications are sent to kiosk admins, in the event that a number plate or PIN is not assigned to a specific alias.">
                            <FormControlLabel
                                control={<Checkbox name="SettingSendAllGateNotificationsToKioskAdmins" checked={settings.SettingSendAllGateNotificationsToKioskAdmins || false} onChange={handleInputChange} />}
                                
                                label="Send all gate open notifications to kiosk admins"
                            />
                        </Tooltip>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider/>
                        <Typography style={{fontWeight: 'bold', paddingBottom: 0, paddingTop: 20}}>Video Messages</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Tooltip title="Enter the number of seconds to wait before visitor timeout (back to main menu)">
                            <TextField fullWidth label="Visitor timeout (seconds)" name="SettingVisitorTimeoutSeconds" value={settings.SettingVisitorTimeoutSeconds} onChange={handleInputChange} variant="outlined" 
                            disabled={!settings.SettingAllowVoicemail}/>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={4}>
                        <Tooltip title="If selected, after a pretermined period of time before someone responds to the kiosk (visitor/delivery), a message will appear offering to take a video message">
                            <FormControlLabel
                                control={<Checkbox name="SettingAllowVoicemail" checked={settings.SettingAllowVoicemail || false} onChange={handleInputChange} />}
                                label="Allow video messages"
                            />
                        </Tooltip>
                    </Grid>
                    <Grid item xs={4}>
                        <Tooltip title="Enter the number of seconds to wait before asking the user to leave a message">
                            <TextField fullWidth label="How many seconds to wait" name="SettingVideoMessageWaitSeconds" value={settings.SettingVideoMessageWaitSeconds} onChange={handleInputChange} variant="outlined" 
                            disabled={!settings.SettingAllowVoicemail}/>
                        </Tooltip>
                    </Grid>

                    <Grid item xs={12}>
                        <Divider/>
                        <Typography style={{fontWeight: 'bold', paddingBottom: 0, paddingTop: 20}}>External Camera Settings</Typography>
                    </Grid>


                    <Grid item xs={3}>
                        <Tooltip title="Enable this if you want to use an external camera as well as the device's built-in camera. To use this feature, a camera must be connected to the device and the camera must be configured to stream video to the device.">
                            <FormControlLabel
                                control={<Checkbox 
                                    name="SettingUseExternalCamera" 
                                    checked={settings.SettingUseExternalCamera || false} 
                                    onChange={handleInputChange} 
                                />}
                                label="Use external IP camera"
                            />
                        </Tooltip>
                    </Grid>

                    <Grid item xs={6}>
                        <Tooltip title="The URL of the external camera. This must be a HLS stream. Leave blank to use IP of the device on which the kiosk is running, in which case you will need the side-loaded app Entrinsic RTSP2HTTP installed on the device.">
                            <TextField
                                fullWidth
                                label="External camera URL"
                                name="SettingExternalCameraURL"
                                value={settings.SettingExternalCameraURL || ''}
                                onChange={handleInputChange}
                                variant="outlined"
                                disabled={!settings.SettingUseExternalCamera}
                            />
                        </Tooltip>
                    </Grid>

                    <Grid item xs={12}>
                        <Divider/>
                        <Typography style={{fontWeight: 'bold', paddingBottom: 0, paddingTop: 20}}>Camera Preview Settings</Typography>
                    </Grid>

                <Grid item xs={3}>
                    <Tooltip title="Always show the kiosk camera preview">
                        <FormControlLabel
                            control={<Checkbox 
                                name="SettingShowKioskCameraPreviewAlways" 
                                checked={settings.SettingShowKioskCameraPreviewAlways || false} 
                                onChange={handleInputChange} 
                            />}
                            label="Always show kiosk camera preview"
                        />
                    </Tooltip>
                </Grid>

                <Grid item xs={3}>
                    <Tooltip title="Show the kiosk camera preview during video calls">
                        <FormControlLabel
                            control={<Checkbox 
                                name="SettingShowKioskCameraPreviewDuringCall" 
                                checked={settings.SettingShowKioskCameraPreviewDuringCall || false} 
                                onChange={handleInputChange}
                                disabled={settings.SettingShowKioskCameraPreviewAlways} 
                            />}
                            label="Show kiosk camera preview during calls"
                        />
                    </Tooltip>
                </Grid>

                <Grid item xs={3}>
                    <Tooltip title="Show the kiosk camera preview when movement is detected">
                        <FormControlLabel
                            control={<Checkbox 
                                name="SettingShowKioskCameraPreviewOnMovement" 
                                checked={settings.SettingShowKioskCameraPreviewOnMovement || false} 
                                onChange={handleInputChange}
                                disabled={settings.SettingShowKioskCameraPreviewAlways}
                            />}
                            label="Show kiosk camera preview on movement"
                        />
                    </Tooltip>
                </Grid>

                <Grid item xs={3}>
                    <Tooltip title="How long to show the kiosk camera preview after movement is detected (seconds)">
                        <TextField
                            fullWidth
                            label="Movement preview duration (seconds)"
                            name="SettingShowKioskCameraPreviewOnMovementTime"
                            value={settings.SettingShowKioskCameraPreviewOnMovementTime || ''}
                            onChange={handleInputChange}
                            variant="outlined"
                            type="number"
                            disabled={!settings.SettingShowKioskCameraPreviewOnMovement || settings.SettingShowKioskCameraPreviewAlways}
                        />
                    </Tooltip>
                </Grid>

                <Grid item xs={3}>
                    <Tooltip title="Always show the external camera preview">
                        <FormControlLabel
                            control={<Checkbox 
                                name="SettingShowExternalCameraPreviewAlways" 
                                checked={settings.SettingShowExternalCameraPreviewAlways || false} 
                                onChange={handleInputChange}
                                disabled={!settings.SettingUseExternalCamera}
                            />}
                            label="Always show external camera preview"
                        />
                    </Tooltip>
                </Grid>

                <Grid item xs={3}>
                    <Tooltip title="Show the external camera preview during video calls">
                        <FormControlLabel
                            control={<Checkbox 
                                name="SettingShowExternalCameraPreviewDuringCall" 
                                checked={settings.SettingShowExternalCameraPreviewDuringCall || false} 
                                onChange={handleInputChange}
                                disabled={!settings.SettingUseExternalCamera || settings.SettingShowExternalCameraPreviewAlways}
                            />}
                            label="Show external camera preview during calls"
                        />
                    </Tooltip>
                </Grid>

                <Grid item xs={3}>
                    <Tooltip title="Show the external camera preview when movement is detected">
                        <FormControlLabel
                            control={<Checkbox 
                                name="SettingShowExternalCameraPreviewOnMovement" 
                                checked={settings.SettingShowExternalCameraPreviewOnMovement || false} 
                                onChange={handleInputChange}
                                disabled={!settings.SettingUseExternalCamera || settings.SettingShowExternalCameraPreviewAlways}
                            />}
                            label="Show external camera preview on movement"
                        />
                    </Tooltip>
                </Grid>

                <Grid item xs={3}>
                    <Tooltip title="How long to show the external camera preview after movement is detected (seconds)">
                        <TextField
                            fullWidth
                            label="Movement preview duration (seconds)"
                            name="SettingShowExternalCameraPreviewOnMovementTime"
                            value={settings.SettingShowExternalCameraPreviewOnMovementTime || ''}
                            onChange={handleInputChange}
                            variant="outlined"
                            type="number"
                            disabled={!settings.SettingShowExternalCameraPreviewOnMovement || settings.SettingShowExternalCameraPreviewAlways || !settings.SettingUseExternalCamera}
                        />
                    </Tooltip>
                </Grid>

                    <Grid item xs={12}>
                        <Divider/>
                        <Typography style={{fontWeight: 'bold', paddingBottom: 0, paddingTop: 20}}>Misc. Settings</Typography>
                    </Grid>
                    {
                        user.partnerName && (
                            <Grid item xs={12}>
                            
                                <Tooltip title="Notifies your partner about kiosk outages by email.">
                                    <FormControlLabel
                                        control={<Checkbox name="SettingEmailOutagesToPartner" checked={settings.SettingEmailOutagesToPartner || false} onChange={handleInputChange} />}
                                        label="Notify partner about outages"
                                    />
                                </Tooltip>
                            </Grid>
                        )
                    }
                    <Grid item xs={6}>
                        <Tooltip title="By default, the front-facing camera is used. However in certain circumstances, such as using the device for ANPR (and where image quality is naturally higher), it may be preferable to use the rear camera.">
                            <FormControlLabel
                                control={<Checkbox name="SettingUseRearCamera" checked={settings.SettingUseRearCamera || false} onChange={handleInputChange} />}
                                label="Use rear device camera"
                            />
                        </Tooltip>
                    </Grid>
                    <Grid item xs={6}>
                        <Tooltip title="Notifies users of the Entrinsic Connect app (with setting enabled) about kiosk outages.">
                            <FormControlLabel
                                control={<Checkbox name="NotifyOutages" checked={settings.NotifyOutages || false} onChange={handleInputChange} />}
                                label="Notify app users about outages"
                            />
                        </Tooltip>
                    </Grid>
                    
                     
                    <Grid item xs={6}>
                        <Tooltip title="Enabling this will show a button in the Connect app's kiosk menu with the label you specify. When the button is tapped, a webhook with the event type of 'Custom' will fire, enabling you to trigger custom actions.">
                            <FormControlLabel
                                control={<Checkbox name="SettingShowCustomActionButtonInAppKioskMenu" checked={settings.SettingShowCustomActionButtonInAppKioskMenu || false} onChange={handleInputChange} />}
                                label="Show custom action button in Connect app kiosk menu"
                            />
                        </Tooltip>
                    </Grid>
                    <Grid item xs={6}>
                        <Tooltip title="Enabling this will show a button in the app during a call. When the button is tapped, a webhook with the event type of 'Custom' will fire, enabling you to trigger custom actions.">
                            <FormControlLabel
                                control={<Checkbox name="SettingShowCustomActionButtonInAppCall" checked={settings.SettingShowCustomActionButtonInAppCall || false} onChange={handleInputChange} />}
                                label="Show custom action button in Connect app during call"
                            />
                        </Tooltip>
                    </Grid>
                    <Grid item xs={6}>
                        <Tooltip title="The label of the custom action button in the Connect app kiosk menu.">
                            <TextField
                            fullWidth
                            label="Custom action label"
                            name="SettingCustomActionLabel"
                            value={settings.SettingCustomActionLabel || ''}
                            onChange={handleInputChange}
                            variant="outlined"
                            disabled={!settings.SettingShowCustomActionButtonInAppKioskMenu && !settings.SettingShowCustomActionButtonInAppCall}
                        />
                        </Tooltip>
                    </Grid>
                    <Grid item xs={3}>
                        <Tooltip title="Tick this box to unlock the kiosk, meaning the Entrinsic Lockdown App will no longer be locking the device, allowing you to access the operating system. It is essential you only tick this box when you are supervising the kiosk to prevent unauthorised access. Note this only takes effect if you are running the Entrinsic Lockdown companion app on the device.">
                            <FormControlLabel
                                control={<Checkbox name="Unlock" checked={settings.Unlock || false} onChange={handleInputChange} />}
                                label="Unlock kiosk"
                            />
                        </Tooltip>
                    </Grid>
                </Grid>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography style={{fontWeight: 'bold', paddingBottom: 0}}>General Settings</Typography>
                    </Grid>
                    <Grid item xs={6}>
                    <Tooltip title="Gate systems can be opened through the action of a voice call and/or text message. Enter the gate number, including international dialling code.">
                            <TextField fullWidth label="Gate Phone Number" name="SettingGatePhoneNumber" value={settings.SettingGatePhoneNumber || ''} onChange={handleInputChange} variant="outlined" />
                        </Tooltip>
                    </Grid>
                    <Grid item xs={6}>
                        <Tooltip title="The word used to describe the 'gate' - e.g. 'Gate', 'Door', 'Barrier'... which appears within both the kiosk in various places and the Connect app.">
                            <TextField fullWidth label="Gate Nomenclature" name="SettingGateNomenclature" value={settings.SettingGateNomenclature || ''} onChange={handleInputChange} variant="outlined" />
                        </Tooltip>
                    </Grid>
                    
                    <Grid item xs={6}>
                    <Tooltip title="Selecting this option will cause a voice call to be made to the gate number. Ensure that the gate accepts calls from the system number, which is +44 7360 269423">
                        <FormControlLabel
                            control={<Checkbox name="SettingCallToOpenGate" checked={settings.SettingCallToOpenGate || false} onChange={handleInputChange} />}
                            label="Call gate phone number to open gate"
                            
                        />
                    </Tooltip>
                    </Grid>
                    <Grid item xs={3}>
                        <Tooltip title="Tick this box if you want the gate to be opened via a Bluetooth relay. Further configuration is required at the kiosk. Note, this feature is only available when using the iOS/Android app for hosting Kiosk UI.">
                            <FormControlLabel
                                control={<Checkbox name="SettingAllowBluetoothRelay" checked={settings.SettingAllowBluetoothRelay || false} onChange={handleInputChange} />}
                                label="Allow bluetooth relay"
                            />
                        </Tooltip>
                    </Grid>
                    <Grid item xs={2}>
                        <Tooltip title="Number of milliseconds to open the relay before closing again. For example, a gate may just require a short on/off (e.g. 100 ms) whilst a door may require you to open it for 10 seconds (10000ms)">
                            <TextField disabled={!settings.SettingAllowBluetoothRelay} fullWidth label="Relay on time (milliseconds)" name="SettingBluetoothOnMilliseconds" value={settings.SettingBluetoothOnMilliseconds || ''} onChange={handleInputChange} variant="outlined" />
                        </Tooltip>
                    </Grid>
                    <Grid item xs={1}>
                         {settings.SettingAllowBluetoothRelay && (
                            <Button disabled={!settings.SettingAllowBluetoothRelay && settings.BluetoothPasscode}
                            onClick={showBlueToothDialog} variant="contained">
                                Reset
                            </Button>
                         )}
                        
                    </Grid>
                    <Grid item xs={6}></Grid>
                    <Grid item xs={6}>
                        <Tooltip title="Choose another device that is configured to use Bluetooth to be signalled as well as (chain) or instead of (delegate) this one.">
                            <FormControl variant="filled" fullWidth='true'>
                                <InputLabel id="kiosk-label">Delegate or chain bluetooth relay to an alternative device</InputLabel>
                                <Select
                                    value={settings.SettingDelegateBluetoothRelayTenantId || 'none'}
                                    onChange={handleInputChange}
                                    label="Delegate or chain bluetooth relay to an alternative device"
                                    labelId="kiosk-label"
                                    name="SettingDelegateBluetoothRelayTenantId"
                                    >
                                    {kiosks.filter(kiosk => kiosk.SettingAllowBluetoothRelay  || kiosk.SettingDelegateBluetoothRelayTenantId || kiosk.id === 'none').map((kiosk) => (
                                        <MenuItem key={kiosk.id} value={kiosk.id}>
                                        {kiosk.OrganisationName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={6}>
                        <Tooltip title="Selecting this open will cause an SMS message to be sent to the gate nuber. Ensure that the gate accepts text messages from the system number, which is +44 7360 269423">
                            <FormControlLabel
                                control={<Checkbox name="SettingSendSMSToOpenGate" checked={settings.SettingSendSMSToOpenGate || false} onChange={handleInputChange} />}
                                label="Send SMS to open gate"
                            />
                        </Tooltip>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label="SMS gate text format"
                            name="SettingGateText"
                            value={settings.SettingGateText || ''}
                            onChange={handleInputChange}
                            variant="outlined"
                            disabled={!settings.SettingSendSMSToOpenGate}
                        />
                    </Grid>

                    <Grid item xs={6}>
                    <Tooltip title="Selecting this option triggers a call to the specified number. This is useful to act as a 'doorbell' for certain situations.">
                        <FormControlLabel
                            control={<Checkbox name="SettingCallToNotify" checked={settings.SettingCallToNotify || false} onChange={handleInputChange} />}
                            label="Call number to notify of visitor/delivery"
                            
                        />
                    </Tooltip>
                    </Grid>
                    <Grid item xs={3}>
                        <TextField fullWidth label="Notify phone number" name="SettingNotifyNumber" disabled={!settings.SettingCallToNotify} value={settings.SettingNotifyNumber || ''} onChange={handleInputChange} variant="outlined" />
                    </Grid>
                    <Grid item xs={3}>
                        <Tooltip title="The number of seconds before cutting off. A short amount of time, such as 6 seconds, caters for a couple of rings, for example. A longer timeout means the phone will keep ringing, and can be answered to make the ringing stop.">
                            <TextField fullWidth label="Notify timeout (seconds)" name="SettingNotifyTimeoutSeconds" disabled={!settings.SettingCallToNotify} value={settings.SettingNotifyTimeoutSeconds || ''} onChange={handleInputChange} variant="outlined" />
                        </Tooltip>
                    </Grid>
                    <Grid item xs={6}>
                        <Tooltip title="Shows the PIN entry option (button) in the Kiosk">
                            <FormControlLabel
                                control={<Checkbox name="SettingIncludePINSButton" checked={settings.SettingIncludePINSButton || false} onChange={handleInputChange} />}
                                label="Include PINs button"
                            />
                        </Tooltip>
                    </Grid>
                    <Grid item xs={6}>
                        <Tooltip title="By enabling this, PINs can be assigned to Aliases for this device. This is so that you can notify specific people in the event of a successful PIN entry.">
                            <FormControlLabel
                                control={<Checkbox name="SettingAllowAliasesForPINs" checked={masterPINAliases ?? (settings.SettingAllowAliasesForPINs || false)} onChange={handleInputChange} />}
                                disabled={!settings.SettingIncludePINSButton || disablePINAliases}  
                                label="Enable aliases for PINs"
                            />
                        </Tooltip>
                        { disablePINAliases && (
                            <Tooltip
                                title={`This option is unavailable because PINs are shared from ${masterTenantNameForPINs}. You need to switch to ${masterTenantNameForPINs} to change this setting.`}
                                >
                                    <WarningIcon color='error' style={{ verticalAlign: 'middle'}} />
                            </Tooltip>
                        )}

                    </Grid>
                    
                    
                    <Grid item xs={3}>
                        <Tooltip title="Shows the Delivery option (button) in the Kiosk">
                            <FormControlLabel
                                control={<Checkbox name="SettingIncludeDeliveryButton" checked={settings.SettingIncludeDeliveryButton || false} onChange={handleInputChange} />}
                                label="Include Delivery Button"
                            />
                        </Tooltip>
                    </Grid>
                    <Grid item xs={3}>
                        <Tooltip title="Tick this box for the second-level delivery menu, whereby users are given a choice to either speak to the home owner or just ask to be let in without speaking to the home owner.">
                            <FormControlLabel
                                control={<Checkbox name="SettingSecondLevelDeliveryOption" checked={settings.SettingSecondLevelDeliveryOption || false} onChange={handleInputChange} />}
                                disabled={!settings.SettingIncludeDeliveryButton}
                                label="Second-level delivery menu"
                            />
                        </Tooltip>
                    </Grid>
                    <Grid item xs={3}>
                        <Tooltip title="Number of seconds to display the welcome message (after manual gate open) before going back to the main menu.">
                            <TextField fullWidth label="Welcome message timeout (seconds)" name="SettingDeliveryOrVisitorMessageTimeoutSeconds" value={settings.SettingDeliveryOrVisitorMessageTimeoutSeconds || ''} onChange={handleInputChange} variant="outlined" />
                        </Tooltip>
                    </Grid>
                    <Grid item xs={3}>
                        <Tooltip title="Number of seconds to display the welcome message from ANPR/PIN entry) before going back to main menu.">
                            <TextField fullWidth label="ANPR/PIN message timeout (seconds)" name="SettingNonInteractiveWelcomeTimeoutSeconds" value={settings.SettingNonInteractiveWelcomeTimeoutSeconds || ''} onChange={handleInputChange} variant="outlined" />
                        </Tooltip>
                    </Grid>
                    
                    <Grid item xs={12}>
                        <Tooltip title="Whether or not to display instructions to visitors">
                            <FormControlLabel
                                control={<Checkbox name="SettingShowEntryInstructionsVisitors" checked={settings.SettingShowEntryInstructionsVisitors || false} onChange={handleInputChange} />}
                                label="Show entry instructions for visitors"
                            />
                        </Tooltip>
                    </Grid>
                    { settings.SettingShowEntryInstructionsVisitors && (
                        <Grid item xs={12}>
                            <Typography variant="h7" style={{ marginBottom: '10px'}}>Entry Instructions for Visitors</Typography>
                            <Typography variant="h7" fontStyle={'italic'} display={'block'} fontSize={'10pt'} >This information appears to the user after the responder lets them in.</Typography>
                            <Paper elevation={3} style={{ padding: '10px', marginTop: '10px' }}>                            
                                <ReactQuill value={settings.SettingEntryInstructionsVisitors || ''} onChange={(content) => handleInputChange({target: {name: 'SettingEntryInstructionsVisitors', value: content}})} 
                                modules={{
                                    clipboard: {
                                        matchVisual: false
                                    }
                                }}/>
                            </Paper>
                        </Grid>
                    )}
                    <Grid item xs={6}>
                        <Tooltip title="Whether or not to display instructions to the delivery person">
                            <FormControlLabel
                                control={<Checkbox name="SettingShowEntryInstructionsDeliveries" checked={settings.SettingShowEntryInstructionsDeliveries || false} onChange={handleInputChange} />}
                                label="Show entry instructions for deliveries"
                                disabled={!settings.SettingIncludeDeliveryButton}
                            />
                        </Tooltip>
                    </Grid>
                    
                    { settings.SettingShowEntryInstructionsDeliveries && settings.SettingIncludeDeliveryButton && (
                        <Grid item xs={12}>
                            <Typography variant="h7" style={{ marginBottom: '10px'}}>Entry Instructions for Deliveries</Typography>
                            <Typography variant="h7" fontStyle={'italic'} display={'block'} fontSize={'10pt'} >This information appears to the user after the responder lets them in.</Typography>
                            <Paper elevation={3} style={{ padding: '10px', marginTop: '10px' }}>
                                <ReactQuill value={settings.SettingEntryInstructionsDeliveries || ''} onChange={(content) => handleInputChange({target: {name: 'SettingEntryInstructionsDeliveries', value: content}})}
                                    modules={{
                                        clipboard: {
                                            matchVisual: false
                                        }
                                    }}
                                />
                            </Paper>
                        </Grid>
                    )}
                    <Grid item xs={12}></Grid>
                    <Grid item xs={6}>
                        <Tooltip title='Restricts access to entry via Number Plate, PIN entry or QR Code Key to specific days of the week and times of the day'>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={settings?.SettingGateOpeningRestrictions || false}
                                        onChange={(e) => {
                                            handleInputChange({
                                                target: {
                                                    name: 'SettingGateOpeningRestrictions',
                                                    value: e.target.checked
                                                }
                                            });
                                        }}
                                        name="SettingGateOpeningRestrictions"
                                    />
                                }
                                label="Enable Gate Opening Day/Time Restrictions"
                            />
                        </Tooltip>
                    </Grid>                    
                    <Grid item xs={6}>
                        <Button 
                            variant="contained" 
                            onClick={() => setGateTimesDialogOpen(true)}
                            disabled={!settings.SettingGateOpeningRestrictions}
                        >
                            Configure Gate Opening Times
                        </Button>
                    </Grid>

                    <GateOpeningTimes
                        open={gateTimesDialogOpen}
                        onClose={() => setGateTimesDialogOpen(false)}
                        settings={settings}
                        gateSchedule={gateSchedule}
                        handleInputChange={handleInputChange}
                        handleScheduleChange={handleScheduleChange}
                        
                    />

                    <Grid item xs={12}>
                        <Divider/>
                        <Typography style={{fontWeight: 'bold', paddingBottom: 0, paddingTop: 20}}>Background Scanning Settings (ANPR and QR Code Keys)</Typography>
                    </Grid>
                    
                   
                    <Grid item xs={6}>
                        <Tooltip title="You can adjust the sensitivity of movement detection to reduce the amount of network traffic. The lower the sensitivity, the more movement is required to trigger a scan.">
                            
                            <Typography gutterBottom>Movement sensitivity for scanning</Typography>
                            <Slider 
                                name="SettingANPRSensitivity"
                                value={settings.SettingANPRSensitivity || ''}
                                onChange={handleInputChange}
                                min={5000000} max={30000000}
                                
                            />
                        </Tooltip>
                    </Grid>
                    <Grid item xs={6}>
                        <Tooltip title="You can mirror scan messages on another device. For example, you may have additional cameras using the rear camera, and you want the messages to display on the kiosk for the visitor to see.">
                            <FormControl variant="filled" fullWidth='true'>
                                <InputLabel id="kiosk-label">Mirror scan messages to device</InputLabel>
                                <Select
                                    value={settings.SettingMirrorANPRWelcomeMessageOnTenantId || 'none'}
                                    onChange={handleInputChange}
                                    label="Mirror scan messages to device"
                                    labelId="kiosk-label"
                                    name="SettingMirrorANPRWelcomeMessageOnTenantId"
                                    disabled={!settings.SettingEnableANPR && !settings.SettingEnableQRCodeKeys}
                                >
                                    {kiosks.map((kiosk) => (
                                        <MenuItem key={kiosk.id} value={kiosk.id}>
                                            {kiosk.OrganisationName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Tooltip>
                    </Grid>
                    
                </Grid>
               
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
                {  /* Checkbox and Button in a flexbox row */}
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={3} alignItems="center" fullWidth={true}>
                        <Grid item xs={12}>
                            <Tooltip title="You can change the kiosk theme to one of a preset template, or customise this further by selecting 'Custom' from the options below.">
                                <FormControl variant="filled" fullWidth='true'>
                                    
                                    <ThemeGallery
                                        themes={kioskThemes}
                                        selectedTheme={settings.KioskThemeID}
                                        onThemeSelect={(themeId) => handleInputChange({ target: { name: "KioskThemeID", value: themeId } })}
                                        onCustomThemeSelect={() => {
                                            if (!settings.SettingCustomKioskTheme) {
                                                setPreviousCustomKioskThemeID(settings.KioskThemeID);
                                                setShowThemeDialog(true);
                                            }
                                        } }
                                    />
                                    
                                </FormControl>
                            </Tooltip>
                        </Grid>
                        {
                            settings && settings.KioskThemeID && kioskThemes && foundTheme?.shortName === 'custom' && (
                                <>
                                <Grid item xs={12}>
                                    <Tooltip title="Specify or choose a background image URL. You can open the gallery to upload a file or specify an existing external URL.">
                                        <Grid container spacing={2} alignItems="center">
                                            <Grid item xs={8}>
                                                <TextField 
                                                    fullWidth 
                                                    label="Background image" 
                                                    name="SettingCustomKioskThemeBackgroundImageURL" 
                                                    value={settings.SettingCustomKioskThemeBackgroundImageURL || ''} 
                                                    onChange={handleInputChange} 
                                                    variant="outlined" 
                                                />
                                            </Grid>
                                            <Grid item xs={1}>
                                                {settings.SettingCustomKioskThemeBackgroundImageURL && (
                                                    <img 
                                                        src={settings.SettingCustomKioskThemeBackgroundImageURL} 
                                                        alt="Preview" 
                                                        style={{ width: '50px', height: '50px', objectFit: 'cover' }} 
                                                    />
                                                )}
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Button onClick={handleOpenGallery} variant='contained'>Open Image Gallery</Button>
                                            </Grid>
                                        </Grid>
                                    </Tooltip>
                                    <Dialog
                                        open={isGalleryOpen}
                                        onClose={handleCloseGallery}
                                        fullWidth
                                        maxWidth="lg"
                                    >
                                        <ImageGallery
                                            onImageSelect={handleImageSelect}
                                            tenantId={globalTenantId}
                                            pickerMode={true}
                                        />
                                    </Dialog>
                                </Grid>
                                <Grid item xs={12}>
                                    <Tooltip title="Specify or choose a background image URL for the landscape view of this theme. You can open the gallery to upload a file or specify an existing external URL.">
                                        <Grid container spacing={2} alignItems="center">
                                            <Grid item xs={8}>
                                                <TextField 
                                                    fullWidth 
                                                    label="Background image (Landscape mode)" 
                                                    name="SettingCustomKioskThemeLandscapeBackgroundImageURL" 
                                                    value={settings.SettingCustomKioskThemeLandscapeBackgroundImageURL || ''} 
                                                    onChange={handleInputChange} 
                                                    variant="outlined" 
                                                />
                                            </Grid>
                                            <Grid item xs={1}>
                                                {settings.SettingCustomKioskThemeLandscapeBackgroundImageURL && (
                                                    <img 
                                                        src={settings.SettingCustomKioskThemeLandscapeBackgroundImageURL} 
                                                        alt="Preview" 
                                                        style={{ width: '50px', height: '50px', objectFit: 'cover' }} 
                                                    />
                                                )}
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Button onClick={handleOpenGallery} variant='contained'>Open Image Gallery</Button>
                                            </Grid>
                                        </Grid>
                                    </Tooltip>
                                    <Dialog
                                        open={isGalleryOpen}
                                        onClose={handleCloseGallery}
                                        fullWidth
                                        maxWidth="lg"
                                    >
                                        <ImageGallery
                                            onImageSelect={handleLandscapeImageSelect}
                                            tenantId={globalTenantId}
                                            pickerMode={true}
                                        />
                                    </Dialog>
                                </Grid>
                                <Grid item xs={6}>
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item xs={8}>
                                            <Tooltip title="Choose theme colour 1 with the colour picker.">
                                                <TextField 
                                                    fullWidth 
                                                    label="Theme colour 1" 
                                                    name="SettingCustomKioskThemeColour1" 
                                                    value={settings.SettingCustomKioskThemeColour1 || ''} 
                                                    onChange={handleInputChange} 
                                                    variant="outlined" 
                                                />
                                            </Tooltip>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Tooltip title="Select colour">
                                                <TextField
                                                    type="color"
                                                    value={settings.SettingCustomKioskThemeColour1 || '#ffffff'}
                                                    onChange={handleInputChange}
                                                    name="SettingCustomKioskThemeColour1"
                                                    variant="outlined"
                                                    style={{ width: '100%', padding: '0px' }}
                                                />
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={6}>
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item xs={8}>
                                            <Tooltip title="Choose theme colour 2 with the colour picker.">
                                                <TextField 
                                                    fullWidth 
                                                    label="Theme colour 2" 
                                                    name="SettingCustomKioskThemeColour2" 
                                                    value={settings.SettingCustomKioskThemeColour2 || ''} 
                                                    onChange={handleInputChange} 
                                                    variant="outlined" 
                                                />
                                            </Tooltip>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Tooltip title="Select colour">
                                                <TextField
                                                    type="color"
                                                    value={settings.SettingCustomKioskThemeColour2 || '#ffffff'}
                                                    onChange={handleInputChange}
                                                    name="SettingCustomKioskThemeColour2"
                                                    variant="outlined"
                                                    style={{ width: '100%', padding: '0px' }}
                                                />
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={6}>
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item xs={8}>
                                            <Tooltip title="Choose theme colour 3 with the colour picker.">
                                                <TextField 
                                                    fullWidth 
                                                    label="Theme colour 3" 
                                                    name="SettingCustomKioskThemeColour3" 
                                                    value={settings.SettingCustomKioskThemeColour3 || ''} 
                                                    onChange={handleInputChange} 
                                                    variant="outlined" 
                                                />
                                            </Tooltip>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Tooltip title="Select colour">
                                                <TextField
                                                    type="color"
                                                    value={settings.SettingCustomKioskThemeColour3 || '#ffffff'}
                                                    onChange={handleInputChange}
                                                    name="SettingCustomKioskThemeColour3"
                                                    variant="outlined"
                                                    style={{ width: '100%', padding: '0px' }}
                                                />
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={6}>
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item xs={8}>
                                            <Tooltip title="Choose theme colour 4 with the colour picker.">
                                                <TextField 
                                                    fullWidth 
                                                    label="Theme colour 4" 
                                                    name="SettingCustomKioskThemeColour4" 
                                                    value={settings.SettingCustomKioskThemeColour4 || ''} 
                                                    onChange={handleInputChange} 
                                                    variant="outlined" 
                                                />
                                            </Tooltip>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Tooltip title="Select colour">
                                                <TextField
                                                    type="color"
                                                    value={settings.SettingCustomKioskThemeColour4 || '#ffffff'}
                                                    onChange={handleInputChange}
                                                    name="SettingCustomKioskThemeColour4"
                                                    variant="outlined"
                                                    style={{ width: '100%', padding: '0px' }}
                                                />
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={6}>
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item xs={8}>
                                            <Tooltip title="Choose theme colour 5 with the colour picker.">
                                                <TextField 
                                                    fullWidth 
                                                    label="Theme colour 5" 
                                                    name="SettingCustomKioskThemeColour5" 
                                                    value={settings.SettingCustomKioskThemeColour5 || ''} 
                                                    onChange={handleInputChange} 
                                                    variant="outlined" 
                                                />
                                            </Tooltip>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Tooltip title="Select colour">
                                                <TextField
                                                    type="color"
                                                    value={settings.SettingCustomKioskThemeColour5 || '#ffffff'}
                                                    onChange={handleInputChange}
                                                    name="SettingCustomKioskThemeColour5"
                                                    variant="outlined"
                                                    style={{ width: '100%', padding: '0px' }}
                                                />
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={6}>
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item xs={8}>
                                            <Tooltip title="Choose theme colour 6 with the colour picker.">
                                                <TextField 
                                                    fullWidth 
                                                    label="Theme colour 6" 
                                                    name="SettingCustomKioskThemeColour6" 
                                                    value={settings.SettingCustomKioskThemeColour6 || ''} 
                                                    onChange={handleInputChange} 
                                                    variant="outlined" 
                                                />
                                            </Tooltip>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Tooltip title="Select colour">
                                                <TextField
                                                    type="color"
                                                    value={settings.SettingCustomKioskThemeColour6 || '#ffffff'}
                                                    onChange={handleInputChange}
                                                    name="SettingCustomKioskThemeColour6"
                                                    variant="outlined"
                                                    style={{ width: '100%', padding: '0px' }}
                                                />
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </>
                        )}
                        <Grid item xs={12}>
                            
                            {
                                settings && settings.KioskThemeID && kioskThemes && foundTheme?.shortName === 'custom' && (
       
                                  
                                //settings.KioskThemeID && kioskThemes && kioskThemes.find(theme => theme.id === settings.KioskThemeID).shortName == 'custom' && (
                                <>
                                <Accordion expanded={isAccordionExpanded} onChange={handleAccordionToggle}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel-css-content"
                                        id="panel-css-header"
                                    >
                                        <Typography>Advanced CSS</Typography>
                                    </AccordionSummary>
                                <AccordionDetails>
                                    <Typography style={{fontSize: 'small', marginBottom: 20}}>You can edit the entire layout and style of the kiosk by editing the CSS below. This is an advanced feature and requires knowledge of CSS.
                                        To use theme colours (than can be easily changed with the selectors above) rather than hard code the values, make them #colour1#, #colour2# etc.
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        label="Custom Kiosk Theme"
                                        name="SettingCustomKioskTheme"
                                        value={settings.SettingCustomKioskTheme || ''}
                                        onChange={handleInputChange}
                                        variant="outlined"
                                        multiline 
                                        maxRows={20}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Tab') {
                                            e.preventDefault();
                                            const textarea = e.target;
                                            const start = textarea.selectionStart;
                                            const end = textarea.selectionEnd;
                                        
                                            // Insert tab character at cursor position
                                            textarea.value = textarea.value.substring(0, start) + '\t' + textarea.value.substring(end);
                                        
                                            // Update cursor position
                                            textarea.selectionStart = textarea.selectionEnd = start + 1;
                                            }
                                        }}
                                    />
                                   
                                    
                                    </AccordionDetails>
                                </Accordion>
                                <Button onClick={() => resetCustomTheme()} variant='contained' style={{marginTop: 10}}>Reset custom theme</Button>
                                </>
                                   
                                )
                            }
                        </Grid>
                    </Grid>
                </Box>
            </CustomTabPanel>

            <CustomTabPanel value={value} index={4}>
                {  /* Checkbox and Button in a flexbox row */}
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={3} alignItems="center" fullWidth={true}>
                    <Grid item sx={4}>
                        <TextField fullWidth label="Visitor Button Text" name="SettingVisitorButtonText" value={settings.SettingVisitorButtonText || ''} onChange={handleInputChange} variant="outlined" />
                    </Grid>
                    <Grid item sx={4}>
                        <TextField fullWidth label="Delivery Button Text" name="SettingDeliveryButtonText" value={settings.SettingDeliveryButtonText || ''} onChange={handleInputChange} variant="outlined" />
                    </Grid>
                    <Grid item sx={4}>
                        <TextField fullWidth label="PIN Button Text" name="SettingPINButtonText" value={settings.SettingPINButtonText || ''} onChange={handleInputChange} variant="outlined" />
                    </Grid>
                    <Grid item sx={4}>
                        <TextField fullWidth label="Choose From Options Text" name="SettingChooseFromOptionsText" value={settings.SettingChooseFromOptionsText || ''} onChange={handleInputChange} variant="outlined" />
                    </Grid>
                    <Grid item sx={4}>
                        <TextField fullWidth label="CCTV Text" name="SettingCCTVText" value={settings.SettingCCTVText || ''} onChange={handleInputChange} variant="outlined" />
                    </Grid>
                    <Grid item sx={4}>
                        <TextField fullWidth label="Powered By Text" name="SettingPoweredByText" value={settings.SettingPoweredByText || ''} onChange={handleInputChange} variant="outlined" />
                    </Grid>
                    <Grid item sx={4}>
                        <TextField fullWidth label="Who Speak Text" name="SettingWhoSpeakText" value={settings.SettingWhoSpeakText || ''} onChange={handleInputChange} variant="outlined" />
                    </Grid>
                    <Grid item sx={4}>
                        <TextField fullWidth label="Cancel Button Text" name="SettingCancelButtonText" value={settings.SettingCancelButtonText || ''} onChange={handleInputChange} variant="outlined" />
                    </Grid>
                    <Grid item sx={4}>
                        <TextField fullWidth label="Please Choose Text" name="SettingPleaseChooseText" value={settings.SettingPleaseChooseText || ''} onChange={handleInputChange} variant="outlined" />
                    </Grid>
                    <Grid item sx={4}>
                        <TextField fullWidth label="Delivery Button No Speak Text" name="SettingDeliveryButtonNoSpeakText" value={settings.SettingDeliveryButtonNoSpeakText || ''} onChange={handleInputChange} variant="outlined" />
                    </Grid>
                    <Grid item sx={4}>
                        <TextField fullWidth label="Delivery Button Speak Text" name="SettingDeliveryButtonSpeakText" value={settings.SettingDeliveryButtonSpeakText || ''} onChange={handleInputChange} variant="outlined" />
                    </Grid>
                    <Grid item sx={4}>
                        <TextField fullWidth label="Enter PIN Text" name="SettingEnterPINText" value={settings.SettingEnterPINText || ''} onChange={handleInputChange} variant="outlined" />
                    </Grid>
                    <Grid item sx={4}>
                        <TextField fullWidth label="Welcome Visitor Text" name="SettingWelcomeVisitorText" value={settings.SettingWelcomeVisitorText || ''} onChange={handleInputChange} variant="outlined" />
                    </Grid>
                    <Grid item sx={4}>
                        <TextField fullWidth label="Gate Opening Text" name="SettingGateOpeningText" value={settings.SettingGateOpeningText || ''} onChange={handleInputChange} variant="outlined" />
                    </Grid>
                    <Grid item sx={4}>
                        <TextField fullWidth label="Connecting Text" name="SettingConnectingText" value={settings.SettingConnectingText || ''} onChange={handleInputChange} variant="outlined" />
                    </Grid>
                    <Grid item sx={4}>
                        <TextField fullWidth label="Nobody Answering Text" name="SettingNobodyAnsweringText" value={settings.SettingNobodyAnsweringText || ''} onChange={handleInputChange} variant="outlined" />
                    </Grid>
                    <Grid item sx={4}>
                        <TextField fullWidth label="Leave Message Text" name="SettingLeaveMessageText" value={settings.SettingLeaveMessageText || ''} onChange={handleInputChange} variant="outlined" />
                    </Grid>
                    <Grid item sx={4}>
                        <TextField fullWidth label="Recording Started Text" name="SettingRecordingStartedText" value={settings.SettingRecordingStartedText || ''} onChange={handleInputChange} variant="outlined" />
                    </Grid>
                    <Grid item sx={4}>
                        <TextField fullWidth label="Save And Send Text" name="SettingSaveAndSendText" value={settings.SettingSaveAndSendText || ''} onChange={handleInputChange} variant="outlined" />
                    </Grid>
                    <Grid item sx={4}>
                        <TextField fullWidth label="Start Again Text" name="SettingStartAgainText" value={settings.SettingStartAgainText || ''} onChange={handleInputChange} variant="outlined" />
                    </Grid>
                    <Grid item sx={4}>
                        <TextField fullWidth label="Message Sent Text" name="SettingMessageSentText" value={settings.SettingMessageSentText || ''} onChange={handleInputChange} variant="outlined" />
                    </Grid>
                    <Grid item sx={4}>
                        <TextField fullWidth label="You Are Connected Text" name="SettingYouAreConnectedText" value={settings.SettingYouAreConnectedText || ''} onChange={handleInputChange} variant="outlined" />
                    </Grid>
                    <Grid item sx={4}>
                        <TextField fullWidth label="Invalid PIN Text" name="SettingInvalidPINText" value={settings.SettingInvalidPINText || ''} onChange={handleInputChange} variant="outlined" />
                    </Grid>
                    <Grid item sx={4}>
                        <TextField fullWidth label="ANPR Welcome Text" name="SettingANPRWelcomeText" value={settings.SettingANPRWelcomeText || ''} onChange={handleInputChange} variant="outlined" />
                    </Grid>
                    <Grid item sx={4}>
                        <TextField fullWidth label="ANPR Authorising Text" name="SettingANPRAuthorisingText" value={settings.SettingANPRAuthorisingText || ''} onChange={handleInputChange} variant="outlined" />
                    </Grid>
                  
                    <Grid item sx={4}>
                        <TextField 
                            fullWidth 
                            label="QR Code Button Text" 
                            name="SettingQRCodeButtonText" 
                            value={settings.SettingQRCodeButtonText || ''} 
                            onChange={handleInputChange} 
                            variant="outlined" 
                        />
                    </Grid>
                    <Grid item sx={4}>
                        <TextField 
                            fullWidth 
                            label="QR Code Scan Text" 
                            name="SettingQRCodeScanText" 
                            value={settings.SettingQRCodeScanText || ''} 
                            onChange={handleInputChange} 
                            variant="outlined" 
                        />
                    </Grid>
                    <Grid item sx={4}>
                        <TextField 
                            fullWidth 
                            label="QR Code Position Text" 
                            name="SettingQRCodePositionText" 
                            value={settings.SettingQRCodePositionText || ''} 
                            onChange={handleInputChange} 
                            variant="outlined" 
                        />
                    </Grid>

                    <Grid item xs={7}>
                            <FormControlLabel
                            control={<Checkbox name="SettingCustomKioskUI" checked={settings.SettingCustomKioskUI || false} onChange={handleCustomKioskUIChange} />}
                            label="Custom Kiosk UI"
                            />
                        </Grid>
                        <Grid item xs={5}>

                            <Typography 
                                sx={{ 
                                    cursor: 'pointer', 
                                    textDecoration: 'underline',
                                    '&:hover': {
                                        color: 'primary.main'
                                    }
                                }}
                                onClick={() => setShowDiffDialog(true)}
                            >
                                {settings.SettingCustomKioskUI && (settings.SettingKioskUI === serverKioskUI ? 
                                    "Default UI is in use - no changes have been made" : 
                                    "Custom UI is in use - click to view changes")}
                            </Typography>
                            {settings.SettingKioskUI !== serverKioskUI && <Button variant="contained" onClick={handleRevertToOriginal}>Revert to original</Button>}
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Kiosk UI"
                                name="SettingKioskUI"
                                value={settings.SettingKioskUI || ''}
                                onChange={handleInputChange}
                                variant="outlined"
                                multiline 
                                disabled={!settings.SettingCustomKioskUI} 
                                maxRows={20}
                                onKeyDown={(e) => {
                                    if (e.key === 'Tab') {
                                        e.preventDefault();
                                        const textarea = e.target;
                                        const start = textarea.selectionStart;
                                        const end = textarea.selectionEnd;
                                    
                                        // Insert tab character at cursor position
                                        textarea.value = textarea.value.substring(0, start) + '\t' + textarea.value.substring(end);
                                    
                                        // Update cursor position
                                        textarea.selectionStart = textarea.selectionEnd = start + 1;
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={5}>
                <Typography style={{marginBottom: 20}}>
                    You may upload images to use in your Custom Theme or Custom UI.
                    Clicking on an image will reveal its public URL (underneath the gallery)
                    which you can copy to clipboard, and then reference in your Custom Theme CSS or Custom UI HTML. 
                </Typography>
                <ImageGallery tenantId={globalTenantId}  />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={6}>
                <Typography variant="h7" component='div' style={{ marginBottom: '20px'}}>
                    ANPR is a system that uses optical character recognition on images to read vehicle registration plates.
                    This data can be used to automatically open gates, doors and barriers for vehicles that are registered with the system.
                </Typography>
                <Typography variant="h7" component='div' style={{ marginBottom: '20px'}}>
                    The kiosk monitors the camera feed, and if movement is detected, it will take a snapshot and send it to the ANPR server for processing.
                    The ANPR server will then send the registration number back to the kiosk, which will then trigger the relevant gate action
                    (call, SMS, bluetooth relay) if the vehicle is registered. For bluetooth, you may delegate this to another device. Finally, if 2-step mobile or push
                    notification authorisation is set up on the relevant vehicle plate(s), this is triggered.
                </Typography>
                <Typography variant="h7" component='div' style={{ marginBottom: '40px'}}>
                    The main kiosk monitors the camera feed, although this can be disabled if the camera is not in the correct position to detect vehicle plates.
                    You can have multiple additional devices checking for registration plates too. 
                    
                </Typography>
                <Grid container spacing={3} alignItems="center" fullWidth={true}>

                    
                    <Grid item xs={12}>
                        <Tooltip title="Enables the ANPR functionality on this kiosk">
                            <FormControlLabel
                                control={<Checkbox name="SettingEnableANPR" checked={settings.SettingEnableANPR || false} onChange={handleInputChange} />}
                                label="Enable ANPR"
                            />
                        </Tooltip>
                    </Grid>
                   

                    <Grid item xs={6}>
                    <Tooltip title="Time in seconds before another ANPR scan of the same number plate is made. This helps prevent duplicate scans of the same number plate.">
                            <TextField
                                fullWidth
                                label="Wait time (seconds) between scans"
                                name="SettingANPRSecondsBeforeReads"
                                value={settings.SettingANPRSecondsBeforeReads || ''}
                                onChange={handleInputChange}
                                variant="outlined"
                                disabled={!settings.SettingEnableANPR}
                            />
                        </Tooltip>                       
                    </Grid>
                    
                    <Grid item xs={6}>
                    <Tooltip title="If you wish for this device to be only monitoring ANPR feeds, set its type to 'Dedicated ANPR Camera' and the 'ANPR Message' field below will be enabled. You can choose what to display on the ANPR UI. Leaving this blank will result in a blank screen. ">
                            <TextField
                                fullWidth
                                label="ANPR Message"
                                name="SettingANPRMessage"
                                value={settings.SettingANPRMessage || ''}
                                onChange={handleInputChange}
                                variant="outlined"
                                multiline 
                                disabled={!settings.SettingEnableANPR} 
                                maxRows={20}
                                onKeyDown={(e) => {
                                    if (e.key === 'Tab') {
                                    e.preventDefault();
                                    const textarea = e.target;
                                    const start = textarea.selectionStart;
                                    const end = textarea.selectionEnd;
                                
                                    // Insert tab character at cursor position
                                    textarea.value = textarea.value.substring(0, start) + '\t' + textarea.value.substring(end);
                                
                                    // Update cursor position
                                    textarea.selectionStart = textarea.selectionEnd = start + 1;
                                    }
                                }}
                            />
                        </Tooltip>
                    </Grid>
                   
                    <Grid item xs={6}>
                        <Tooltip title="Enables the unrecognised number plate feature. In this case, if an unrecognised number plate is detected, the Entrinsic Connect app will alert users that there is a
                        vehicle at the kiosk. Users will be given the option to open the gate or show a custom message on the kiosk.">
                            <FormControlLabel
                                control={<Checkbox name="SettingANPRUnrecognisedVehicleWorkflow"
                                checked={settings.SettingANPRUnrecognisedVehicleWorkflow || false}
                                onChange={handleInputChange} />}
                                disabled={!settings.SettingEnableANPR}
                                label="Enable unrecognised vehicle workflow"
                            />
                        </Tooltip>
                    </Grid>

                    <Grid item xs={6}>
                        <Tooltip title="The notification sent is either to all kiosk admins or all kiosk responders">
                            <FormControl variant="filled" fullWidth='true'>
                                <InputLabel id="theme-label">Notification option</InputLabel>
                                <Select
                                    value={settings.SettingANPRUnrecognisedNotificationOption || 'none'}
                                    onChange={handleInputChange}
                                    label="Notification option"
                                    labelId="kiosk-label"
                                    name="SettingANPRUnrecognisedNotificationOption"
                                    disabled={!settings.SettingANPRUnrecognisedVehicleWorkflow || !settings.SettingEnableANPR} >
                                    <MenuItem key='SettingANPRUnrecognisedNotificationOption1' value='1'>All Kiosk Admins</MenuItem>
                                    <MenuItem key='SettingANPRUnrecognisedNotificationOption2' value='2'>All Kiosk Responders</MenuItem>
                                </Select>
                            </FormControl>
                        </Tooltip>
                    </Grid>

 
                    <Grid item xs={6}>
                        <Tooltip title="Time in milliseconds before another photo is taken and sent to the ANPR reader. Sometimes you might prefer a delay to avoid blurriness.">
                            <TextField
                                fullWidth
                                label="Wait time (milliseconds) before picture taken"
                                name="SettingANPRWaitTimeMilliseconds"
                                value={settings.SettingANPRWaitTimeMilliseconds || ''}
                                onChange={handleInputChange}
                                variant="outlined"
                                multiline 
                                disabled={!settings.SettingEnableANPR} 
                            />
                        </Tooltip>
                    </Grid>

                    <Grid item xs={3}>
                        <Tooltip title="By enabling this, number plates (for ANPR) can be assigned to Aliases for this device. This is so that you can notify specific people in the event of a successful ANPR read and gate entry.">
                            <FormControlLabel
                                control={<Checkbox name="SettingAllowAliasesForNumberPlates" 
                                checked={masterPlateAliases ?? (settings.SettingAllowAliasesForNumberPlates || false)} onChange={handleInputChange} />}
                                disabled={!settings.SettingEnableANPR || disableNumberPlateAliases}  
                                label="Enable aliases"
                            />
                        </Tooltip>
                        { disableNumberPlateAliases && (
                            <Tooltip
                                title={`This option is unavailable because Number Plates are shared from ${masterTenantNameForNumberPlates}. You need to switch to ${masterTenantNameForNumberPlates} to change this setting.`}
                                >
                                    <WarningIcon color='error' style={{ verticalAlign: 'middle'}} />
                            </Tooltip>
                        )}
                    </Grid>

                    <Grid item xs={3}>
                        <Tooltip title="Use an external camera for ANPR">
                            <FormControlLabel
                                control={<Checkbox name="SettingUseExternalCameraForANPR" checked={settings.SettingUseExternalCameraForANPR || false} onChange={handleInputChange} />}
                                label="Use external camera for ANPR"
                                disabled={!settings.SettingEnableANPR}
                            />
                        </Tooltip>
                    </Grid>
                  
                    
                
                </Grid>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={7}>
                <Typography variant="h7" component='div' style={{ marginBottom: '20px'}}>
                    QR Code Keys allow you to generate and manage unique QR codes that can be used to open gates or doors. When scanned by the kiosk, 
                    the system will verify the code and trigger the configured gate action.
                </Typography>
                <Grid container spacing={3} alignItems="center" fullWidth={true}>
                    <Grid item xs={12}>
                        <Tooltip title="Enables the QR Code Key functionality on this kiosk">
                            <FormControlLabel
                                control={<Checkbox 
                                    name="SettingEnableQRCodeKeys" 
                                    checked={settings.SettingEnableQRCodeKeys || false} 
                                    onChange={handleInputChange} 
                                />}
                                label="Enable QR Code Keys"
                            />
                        </Tooltip>
                    </Grid>
                    <Grid item xs={6}>
                        <Tooltip title="If enabled, a QR Code Key button will be displayed on the kiosk UI, which helps users with a visual guide to scan a QR Code Key to open the gate. If disabled, the QR Code Key button will not be displayed and QR codes will be scanned automatically when detected by the camera.">
                            <FormControlLabel
                                control={<Checkbox 
                                    name="SettingIncludeQRCodeKeyButton" 
                                    checked={settings.SettingIncludeQRCodeKeyButton || false} 
                                    onChange={handleInputChange} 
                                    disabled={!settings.SettingEnableQRCodeKeys}
                                />}
                                label="Include QR Code Key button UI (recommended)"
                            />
                        </Tooltip>
                    </Grid>

                    <Grid item xs={3}>
                        <Tooltip title="Time in seconds before a scan of the same QR code is made. This helps prevent duplicate scans of the same code.">
                            <TextField
                                fullWidth
                                label="Wait time (seconds) between scans"
                                name="SettingQRCodeKeysSecondsBeforeReads"
                                value={settings.SettingQRCodeKeysSecondsBeforeReads || ''}
                                onChange={handleInputChange}
                                variant="outlined"
                                disabled={!settings.SettingEnableQRCodeKeys}
                            />
                        </Tooltip>
                    </Grid>

                    <Grid item xs={6}>
                        <Tooltip title="By enabling this, QR Code Keys can be assigned to Aliases for this device. This is so that you can notify specific people in the event of a successful QR Code scan and gate entry.">
                            <FormControlLabel
                                control={<Checkbox 
                                    name="SettingAllowAliasesForQrCodeKeys" 
                                    checked={masterQrCodeKeyAliases ?? (settings.SettingAllowAliasesForQrCodeKeys || false)} 
                                    onChange={handleInputChange} 
                                />}
                                disabled={!settings.SettingEnableQRCodeKeys || disableQrCodeKeysAliases}
                                label="Enable aliases"
                            />
                        </Tooltip>
                        { disableQrCodeKeysAliases && (
                            <Tooltip title={`This option is unavailable because QR Code Keys are shared from ${masterTenantNameForQrCodeKeys}. You need to switch to ${masterTenantNameForQrCodeKeys} to change this setting.`}>
                                <WarningIcon color='error' style={{ verticalAlign: 'middle'}} />
                            </Tooltip>
                        )}
                    </Grid>
                    <Grid item xs={3}>
                        <Tooltip title="Optional. Set a security level for this kiosk. QR Code Keys must have an equal or higher security level to access this kiosk.">
                            <TextField
                                fullWidth
                                disabled={!settings.SettingEnableQRCodeKeys}
                                label="Security Level"
                                name="securityLevel"
                                type="number"
                                value={settings.securityLevel ?? ''}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    setSettings(prev => ({
                                        ...prev,
                                        securityLevel: value === '' ? null : value
                                    }));
                                    setIsModified(true);
                                }}
                                variant="outlined"
                                inputProps={{ min: 1 }}
                                error={settings.securityLevel !== '' && settings.securityLevel !== null && 
                                    (isNaN(parseInt(settings.securityLevel)) || parseInt(settings.securityLevel) <= 0)}
                            />
                        </Tooltip>
                    </Grid>
                </Grid>
                
                
            </CustomTabPanel>
            <Grid item xs={12}>
                <Button type="submit" variant="contained" color="primary" fullWidth disabled={!isModified || loading}>{ loading ? <CircularProgress size={25} /> : 'Save' }</Button>
            </Grid>
         
          </form>
        
        <Dialog open={openWarningDialog} onClose={() => setOpenWarningDialog(false)}>
            <DialogTitle>Warning</DialogTitle>
            <DialogContent>
                <Typography>
                Are you sure you want to edit the HTML of the Kiosk? This should be done with care, as you can break the Kiosk if you make an error in your HTML code. However, you can always revert back to the original should there be an issue.
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpenWarningDialog(false)}>Cancel</Button>
                <Button onClick={() => {
                handleInputChange({ target: { name: "SettingCustomKioskUI", type: "checkbox", checked: true } });
                setOpenWarningDialog(false);
                }}>Proceed</Button>
            </DialogActions>
        </Dialog>
        
        <Dialog
            open={openRevertWarningDialog}
            onClose={() => setOpenRevertWarningDialog(false)}
        >
            <DialogTitle>Warning</DialogTitle>
            <DialogContent>
            <Typography>
                Are you sure you want to revert to the server original? 
                You will lose any customisations that you have made.
            </Typography>
            </DialogContent>
            <DialogActions>
            <Button onClick={() => setOpenRevertWarningDialog(false)}>Cancel</Button>
            <Button onClick={confirmRevertToOriginal}>OK</Button>
            </DialogActions>
        </Dialog>

        <Dialog open={showThemeDialog} >
            <DialogTitle>Reset Custom Theme</DialogTitle>
            <DialogContent>
                
                <Typography>
                    Choose the theme you want to use as a starting point. You can then customise the theme images, colours and even the CSS code.
                </Typography>
                <FormControl variant="filled" fullWidth='true'>
                <InputLabel id="theme-label">Custom kiosk theme starting point</InputLabel>
                <Select
                    value={customKioskThemeID}
                    onChange={(e)=>{
                        setCustomKioskThemeID(e.target.value);
                        }
                    }
                    label="Custom kiosk theme starting point"
                    labelId="theme-label"
                    name="CustomKioskThemeID"
                    required
                    >
                    {kioskThemes.map((theme) => (
                        theme.shortName!='custom' && (
                        <MenuItem key={theme.id} value={theme.id}>
                        {theme.name}
                        </MenuItem>
                        )
                    ))}
                </Select>
            </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={ handleCustomThemeCancel }>Cancel</Button>
                <Button disabled={!customKioskThemeID} onClick={() => {
                    handleCustomTheme();
                }}>Proceed</Button>
            </DialogActions>
        </Dialog>

        <Dialog open={showResetBluetoothDialog} onClose={() => setShowResetBluetoothDialog(false)} maxWidth={'md'}>
            <DialogTitle>Reset Bluetooth Relay</DialogTitle>
            <DialogContent>
                <Typography>
                    {
                        settings.ResetBluetooth && (
                            <Typography style={{color: 'red', marginBottom: 20}}>Pending reset</Typography>
                        )
                    }
                    <div>Your Bluetooth relay has a passcode which is how we communicate with and identify it.
                    The current passcode is {settings.BluetoothPasscode} which 
                    was the last previously set passcode on the physical relay using the app.
                    You may reset the relay in one of three ways. </div>
                    <RadioGroup
                        aria-labelledby="radio-buttons-group-label"
                        //defaultValue={settings.BluetoothPasscode ? 'basic' : 'reset'} 
                        value={bluetoothResetOption}
                        name="radio-buttons-group"
                        onChange={(e) => {setBluetoothResetOption(e.target.value)}}
                    >
                        <FormControlLabel value="basic" control={<Radio disabled={!settings.BluetoothPasscode} />} label="Basic Reset" /> Selecting this option will allow you to run through the
                        Bluetooth setup on the app. The app will use the existing passcode of {settings.BluetoothPasscode} to reset your relay with a 
                        new passcode, thereby re-pairing with this kiosk.
                        Use this option if you are using the relay currently set up with this kiosk, and you just want
                        to go back to the Bluetooth set up screen to set a new passcode.
                        <FormControlLabel value="reset" control={<Radio />} label="Reset using the passcode 1234" /> Use this option if you 
                        are setting up a brand new relay. New relays start with the passcode 1234. As you run through the set up on the app, the relay will be 
                        reset with a new passcode and paired with this kiosk.
                        <FormControlLabel value="custom" control={<Radio />} label="Reset using a custom passcode" /> 
                        Use this option if you are
                        changing a pre-configured relay to be used on this kiosk, where you already know the passcode
                        that was previously set on the relay. For example, you are swapping your relay from one device to another.
                        <TextField label='Custom passcode' style={{marginTop: 10}} disabled={bluetoothResetOption!='custom'}  size='small' onChange={(e) => {setBluetoothCustomPasscode(e.target.value)}} />
                        {
                            settings.ResetBluetooth && (
                                <>
                                <FormControlLabel value="cancel" control={<Radio />} label="Cancel reset" />
                                Choose this option if you want to cancel the pending reset. 
                                </>
                            )
                        }
                    </RadioGroup>
                    <div>&nbsp;</div>
                    
                    
                </Typography>
                
            </DialogContent>
            <DialogActions>
                <Button onClick={() => confirmResetBluetooth()} >OK</Button>
                <Button onClick={() => setShowResetBluetoothDialog(false)} >Cancel</Button>
            </DialogActions>
        </Dialog>

        <Dialog 
            open={showDiffDialog} 
            onClose={() => setShowDiffDialog(false)}
            maxWidth="lg"
            fullWidth
        >
            <DialogTitle>Custom UI Changes</DialogTitle>
            <DialogContent>
                <ReactDiffViewer
                    oldValue={serverKioskUI || ''}
                    newValue={settings.SettingKioskUI || ''}
                    splitView={false}
                    hideLineNumbers={false}
                    showDiffOnly={false}
                    styles={{
                        contentText: {
                            fontFamily: 'monospace',
                            fontSize: '13px'
                        }
                    }}
                />
            </DialogContent>
        </Dialog>

        
      </Container>
    </Layout>
  );
};

export default SettingsPage;