import * as React from 'react';
import { useState, useRef } from 'react';
import { 
  Button, CssBaseline, TextField, Link, Grid, Box, Typography, Container, 
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, 
  Snackbar, Alert, CircularProgress, FormControlLabel, Checkbox, Tooltip, 
  Paper, useTheme, InputAdornment, alpha
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import BusinessIcon from '@mui/icons-material/Business';
import LockIcon from '@mui/icons-material/Lock';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://www.entrinsic.io/">
        Entrinsic Connect
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: '#3a7bd5',
    },
    secondary: {
      main: '#00b8d4',
    },
  },
  shape: {
    borderRadius: 8,
  },
});

const SignUpForm = ({ setShowDialog, setShowAlert, setAlertMessage, navigate }) => {
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [signingUp, setSigningUp] = useState(false);
  const [confirmEmailError, setConfirmEmailError] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const captchaToken = useRef(null);
  const formRef = React.useRef(null);
  const [termsChecked, setTermsChecked] = useState(false);
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [organisationError, setOrganisationError] = useState("");
  const theme = useTheme();

  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleReCaptchaVerify = async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }
    const token = await executeRecaptcha('signup');
    captchaToken.current = token;
  };
  
  const isStrongPassword = (password) => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W).{8,}$/;
    return regex.test(password);
  };

  const isValidEmail = (email) => {
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return regex.test(email);
  };
  
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!formRef.current) return;

    await handleReCaptchaVerify();

    const data = new FormData(formRef.current);

    const firstName = data.get('firstName');
    const lastName = data.get('lastName');
    const organisation = data.get('organisation');

    if (!firstName.trim()) {
      setFirstNameError("Please enter your first name.");
      return;
    } else {
      setFirstNameError("");
    }

    if (!lastName.trim()) {
      setLastNameError("Please enter your last name.");
      return;
    } else {
      setLastNameError("");
    }

    if (!organisation.trim()) {
      setOrganisationError("Please enter a name for your device, home or business.");
      return;
    } else {
      setOrganisationError("");
    }

    const email = data.get('email');

    if (email !== confirmEmail) {
      setConfirmEmailError("Email addresses do not match!");
      return;
    } else {
      setConfirmEmailError("");
    }

    if (!isValidEmail(email)) {
      setEmailError("Please enter a valid email address.");
      return;
    } else {
      setEmailError("");
    }

    if (!captchaToken.current) {
      setAlertMessage("Please complete the CAPTCHA verification.");
      setShowAlert(true);
      return;
    }

    const password = data.get('password');
    const confirmPassword = data.get('confirmPassword');

    if (!isStrongPassword(password)) {
      setPasswordError("Password should be strong with at least 8 characters, including uppercase, lowercase, number, and a special character.");
      return;
    } else {
      setPasswordError("");
    }

    if (password !== confirmPassword) {
      setConfirmPasswordError("Passwords do not match!");
      return;
    } else {
      setConfirmPasswordError("");
    }

    if (!termsChecked) {
      setAlertMessage("You must agree to the Terms and Conditions to sign up.");
      setShowAlert(true);
      return;
    }

    try {
      setSigningUp(true);
      const response = await fetch('/api/signup', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          firstName: data.get('firstName'),
          lastName: data.get('lastName'),
          email: data.get('email'),
          organisation: data.get('organisation'),
          password: password,
          captchaToken: captchaToken.current
        })
      });

      const result = await response.json();

      if (result.success) {
        setShowDialog(true);
      } else {
        setAlertMessage(result.message || "Sign up failed. Please try again.");
        setShowAlert(true);
      }
    } catch (error) {
      console.error("Error during sign up:", error);
      setAlertMessage("An error occurred during sign up. Please try again later.");
      setShowAlert(true);
    } finally {
      setSigningUp(false);
    }
  };

  return (
    <Box 
      component="form" 
      ref={formRef} 
      noValidate 
      onSubmit={handleSubmit} 
      sx={{ 
        mt: 3, 
        width: '100%',
        '& .MuiTextField-root': {
          mb: 1
        }
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            autoComplete="given-name"
            name="firstName"
            required
            fullWidth
            id="firstName"
            label="First Name"
            autoFocus
            error={Boolean(firstNameError)}
            helperText={firstNameError}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PersonIcon color="primary" />
                </InputAdornment>
              ),
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: 2,
                transition: 'all 0.2s ease',
                '&:hover': {
                  boxShadow: '0 0 0 2px rgba(58, 123, 213, 0.1)'
                },
                '&.Mui-focused': {
                  boxShadow: '0 0 0 2px rgba(58, 123, 213, 0.2)'
                }
              }
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            fullWidth
            id="lastName"
            label="Last Name"
            name="lastName"
            autoComplete="family-name"
            error={Boolean(lastNameError)}
            helperText={lastNameError}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PersonIcon color="primary" />
                </InputAdornment>
              ),
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: 2,
                transition: 'all 0.2s ease',
                '&:hover': {
                  boxShadow: '0 0 0 2px rgba(58, 123, 213, 0.1)'
                },
                '&.Mui-focused': {
                  boxShadow: '0 0 0 2px rgba(58, 123, 213, 0.2)'
                }
              }
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            error={Boolean(emailError)}
            helperText={emailError}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailIcon color="primary" />
                </InputAdornment>
              ),
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: 2,
                transition: 'all 0.2s ease',
                '&:hover': {
                  boxShadow: '0 0 0 2px rgba(58, 123, 213, 0.1)'
                },
                '&.Mui-focused': {
                  boxShadow: '0 0 0 2px rgba(58, 123, 213, 0.2)'
                }
              }
            }}
          />
        </Grid> 
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            id="confirmEmail"
            label="Confirm Email Address"
            name="confirmEmail"
            autoComplete="email"
            value={confirmEmail}
            onChange={(e) => setConfirmEmail(e.target.value)}
            error={Boolean(confirmEmailError)}
            helperText={confirmEmailError}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailIcon color="primary" />
                </InputAdornment>
              ),
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: 2,
                transition: 'all 0.2s ease',
                '&:hover': {
                  boxShadow: '0 0 0 2px rgba(58, 123, 213, 0.1)'
                },
                '&.Mui-focused': {
                  boxShadow: '0 0 0 2px rgba(58, 123, 213, 0.2)'
                }
              }
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Tooltip title="This is a friendly name of your device or home or business. Examples are 'Home', 'The Gables' or 'Widgets Ltd'. You can always change it later.">
            <TextField
              required
              fullWidth
              id="organisation"
              label="Device, Home or Business Name"
              name="organisation"
              autoComplete=""
              error={Boolean(organisationError)}
              helperText={organisationError}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <BusinessIcon color="primary" />
                  </InputAdornment>
                ),
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: 2,
                  transition: 'all 0.2s ease',
                  '&:hover': {
                    boxShadow: '0 0 0 2px rgba(58, 123, 213, 0.1)'
                  },
                  '&.Mui-focused': {
                    boxShadow: '0 0 0 2px rgba(58, 123, 213, 0.2)'
                  }
                }
              }}
            />
          </Tooltip>
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="new-password"
            error={Boolean(passwordError)}
            helperText={passwordError}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockIcon color="primary" />
                </InputAdornment>
              ),
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: 2,
                transition: 'all 0.2s ease',
                '&:hover': {
                  boxShadow: '0 0 0 2px rgba(58, 123, 213, 0.1)'
                },
                '&.Mui-focused': {
                  boxShadow: '0 0 0 2px rgba(58, 123, 213, 0.2)'
                }
              }
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            name="confirmPassword"
            label="Confirm Password"
            type="password"
            id="confirmPassword"
            autoComplete="new-password"
            error={Boolean(confirmPasswordError)}
            helperText={confirmPasswordError}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockIcon color="primary" />
                </InputAdornment>
              ),
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: 2,
                transition: 'all 0.2s ease',
                '&:hover': {
                  boxShadow: '0 0 0 2px rgba(58, 123, 213, 0.1)'
                },
                '&.Mui-focused': {
                  boxShadow: '0 0 0 2px rgba(58, 123, 213, 0.2)'
                }
              }
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox 
                value="agree" 
                color="primary" 
                checked={termsChecked}
                onChange={(e) => setTermsChecked(e.target.checked)}
                sx={{
                  '&.Mui-checked': {
                    color: theme.palette.primary.main,
                  }
                }}
              />
            }
            label={
              <Typography variant="body2">
                I agree to the{' '}
                <Link 
                  href="https://entrinsic.io/terms-and-conditions" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  sx={{
                    color: theme.palette.primary.main,
                    textDecoration: 'none',
                    fontWeight: 500,
                    '&:hover': {
                      textDecoration: 'underline'
                    }
                  }}
                >
                  Terms and Conditions
                </Link>
              </Typography>
            }
          />
        </Grid>
      </Grid>
      
      <Button
        type="submit"
        fullWidth
        variant="contained"
        sx={{ 
          mt: 3, 
          mb: 2,
          py: 1.5,
          borderRadius: 2,
          fontWeight: 600,
          boxShadow: '0 4px 12px rgba(58, 123, 213, 0.2)',
          transition: 'all 0.2s ease',
          '&:hover': {
            boxShadow: '0 6px 16px rgba(58, 123, 213, 0.3)',
            transform: 'translateY(-2px)'
          }
        }}
        disabled={signingUp}
      >
        {signingUp ? <CircularProgress size={24} /> : 'Sign Up'}
      </Button>
      
      <Grid container justifyContent="center">
        <Grid item>
          <Link 
            href="/login" 
            variant="body2"
            sx={{
              textDecoration: 'none',
              color: theme.palette.primary.main,
              fontWeight: 500,
              transition: 'color 0.2s ease',
              '&:hover': {
                color: theme.palette.primary.dark,
                textDecoration: 'underline'
              }
            }}
          >
            Already have an account? Sign in
          </Link>
        </Grid>
      </Grid>
    </Box>
  );
};

export default function SignUpPage() {
  const [showDialog, setShowDialog] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const navigate = useNavigate();
  const theme = useTheme();

  return (
    <GoogleReCaptchaProvider reCaptchaKey="6Lc1uyopAAAAANOL6i9Mi0q_sBDncbXU9Uoe1oLv">
      <ThemeProvider theme={defaultTheme}>
        <Box
          sx={{
            minHeight: '100vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            background: 'linear-gradient(135deg, #f5f7fa 0%, #e4e8f0 100%)',
            py: 4
          }}
        >
          <Container component="main" maxWidth="sm">
            <CssBaseline />
            <Paper
              elevation={3}
              sx={{
                p: 4,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                borderRadius: 3,
                boxShadow: '0 8px 32px rgba(0, 0, 0, 0.1)',
                background: 'white',
                overflow: 'hidden',
                position: 'relative',
                '&::before': {
                  content: '""',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  height: '4px',
                  background: 'linear-gradient(90deg, #3a7bd5 0%, #00b8d4 100%)',
                }
              }}
            >
              <Box sx={{ mb: 3, textAlign: 'center' }}>
                <img 
                  src='/Entrinsic_Logo.png' 
                  alt="Entrinsic Logo" 
                  style={{ 
                    height: '60px',
                    marginBottom: '16px'
                  }}
                />
                <Typography 
                  component="h1" 
                  variant="h4" 
                  sx={{ 
                    fontWeight: 600,
                    color: theme.palette.text.primary,
                    mb: 1
                  }}
                >
                  Sign up
                </Typography>
                <Typography 
                  variant="body2" 
                  color="text.secondary" 
                  align="center" 
                  sx={{ 
                    maxWidth: '500px',
                    mx: 'auto',
                    mb: 3
                  }}
                >
                  Please enter the details below to sign up for your free account. No credit card is required. 
                  Upon clicking Sign Up, you will receive an email to verify your account.
                  You may optionally purchase a subscription for advanced features as described on the{' '}
                  <Link 
                    href="https://entrinsic.io/pricing" 
                    target="_blank"
                    sx={{
                      color: theme.palette.primary.main,
                      textDecoration: 'none',
                      fontWeight: 500,
                      '&:hover': {
                        textDecoration: 'underline'
                      }
                    }}
                  >
                    Entrinsic Connect website
                  </Link>. 
                  No charges or fees are taken without your express permission.
                </Typography>
              </Box>
              
              <SignUpForm 
                setShowDialog={setShowDialog} 
                setShowAlert={setShowAlert} 
                setAlertMessage={setAlertMessage} 
                navigate={navigate} 
              />
            </Paper>
            
            <Dialog
              open={showDialog}
              onClose={() => setShowDialog(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              PaperProps={{
                sx: {
                  borderRadius: 3,
                  boxShadow: '0 8px 32px rgba(0, 0, 0, 0.1)',
                }
              }}
            >
              <DialogTitle id="alert-dialog-title">
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <VerifiedUserIcon color="success" />
                  <Typography variant="h6">Sign Up Successful</Typography>
                </Box>
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Please check your inbox for a verification link to complete the sign-up process.
                </DialogContentText>
              </DialogContent>
              <DialogActions sx={{ p: 2 }}>
                <Button 
                  onClick={() => {
                    setShowDialog(false);
                    navigate("/login"); 
                  }} 
                  color="primary"
                  variant="contained"
                  sx={{ 
                    borderRadius: 2,
                    px: 3
                  }}
                >
                  OK
                </Button>
              </DialogActions>
            </Dialog>

            <Snackbar 
              open={showAlert} 
              autoHideDuration={6000} 
              onClose={() => setShowAlert(false)}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
              <Alert 
                onClose={() => setShowAlert(false)} 
                severity="error" 
                variant="filled"
                sx={{ 
                  borderRadius: 2,
                  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)'
                }}
              >
                {alertMessage}
              </Alert>
            </Snackbar>

            <Copyright sx={{ mt: 4, mb: 2, color: 'rgba(0,0,0,0.6)' }} />
          </Container>
        </Box>
      </ThemeProvider>
    </GoogleReCaptchaProvider>
  );
}