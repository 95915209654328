import React, { useState } from 'react';
import { 
  Button, TextField, Typography, Container, Box, 
  CssBaseline, Avatar, InputAdornment, Paper, Link
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import EmailIcon from '@mui/icons-material/Email';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://entrinsic.io/">
        Entrinsic Connect
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

function ResetPassword() {
    const defaultTheme = createTheme({
      palette: {
        primary: {
          main: '#3a7bd5',
        },
        secondary: {
          main: '#00b8d4',
        },
      },
      shape: {
        borderRadius: 8,
      },
    });
    
    const [email, setEmail] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsSubmitting(true);
        
        try {
            const response = await fetch('/api/resetpassword', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email })
            });

            const data = await response.json();

            if (data.success) {
                navigate('/login?passwordReset=true');
            } else {
                alert('There was an error sending the password reset email. Please try again later.');
            }
        } catch (error) {
            console.error("Reset password error:", error);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <ThemeProvider theme={defaultTheme}>
          <Box
            sx={{
              minHeight: '100vh',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              background: 'linear-gradient(135deg, #f5f7fa 0%, #e4e8f0 100%)',
              py: 4
            }}
          >
            <Container component="main" maxWidth="xs">
              <CssBaseline />
              <Paper
                elevation={3}
                sx={{
                  p: 4,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  borderRadius: 3,
                  boxShadow: '0 8px 32px rgba(0, 0, 0, 0.1)',
                  position: 'relative',
                  overflow: 'hidden',
                  '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    height: '4px',
                    background: 'linear-gradient(90deg, #3a7bd5 0%, #00b8d4 100%)',
                  }
                }}
              >
                <Box sx={{ mb: 3, textAlign: 'center' }}>
                  <img 
                    src='/Entrinsic_Logo.png' 
                    alt="Entrinsic Logo" 
                    style={{ 
                      height: '60px',
                      marginBottom: '16px'
                    }}
                  />
                  <Typography 
                    component="h1" 
                    variant="h5" 
                    sx={{ 
                      fontWeight: 600,
                      mb: 1
                    }}
                  >
                    Reset Password
                  </Typography>
                  <Typography 
                    variant="body2" 
                    color="text.secondary"
                  >
                    Enter your email address and we'll send you a link to reset your password.
                  </Typography>
                </Box>
                
                <Box component="form" onSubmit={handleSubmit} sx={{ width: '100%' }}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <EmailIcon color="primary" />
                        </InputAdornment>
                      ),
                    }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        borderRadius: 2,
                        transition: 'all 0.2s ease',
                        '&:hover': {
                          boxShadow: '0 0 0 2px rgba(58, 123, 213, 0.1)'
                        },
                        '&.Mui-focused': {
                          boxShadow: '0 0 0 2px rgba(58, 123, 213, 0.2)'
                        }
                      }
                    }}
                  />
                  
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    disabled={isSubmitting}
                    sx={{
                      mt: 3,
                      mb: 2,
                      py: 1.5,
                      borderRadius: 2,
                      fontWeight: 600,
                      textTransform: 'none',
                      boxShadow: '0 4px 12px rgba(58, 123, 213, 0.2)',
                      background: 'linear-gradient(90deg, #3a7bd5 0%, #00b8d4 100%)',
                      '&:hover': {
                        boxShadow: '0 6px 16px rgba(58, 123, 213, 0.3)',
                        transform: 'translateY(-2px)'
                      }
                    }}
                  >
                    {isSubmitting ? 'Sending...' : 'Reset Password'}
                  </Button>
                  
                  <Box sx={{ textAlign: 'center', mt: 2 }}>
                    <Link 
                      href="/login" 
                      variant="body2"
                      sx={{
                        color: defaultTheme.palette.primary.main,
                        textDecoration: 'none',
                        fontWeight: 500,
                        '&:hover': {
                          textDecoration: 'underline'
                        }
                      }}
                    >
                      Back to Login
                    </Link>
                  </Box>
                </Box>
              </Paper>
              
              <Copyright sx={{ mt: 4 }} />
            </Container>
          </Box>
        </ThemeProvider>
    );
}

export default ResetPassword;
