import React from 'react';
import './axiosConfig';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './AuthContext';
import ProtectedRoute from './protectedRoute';
import { ThemeProvider, CssBaseline } from '@mui/material';
import theme from './theme/theme'; // Import your custom theme
import { routes } from './routesConfig';
import { UnreadCountProvider } from './UnreadCountContext';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import './App.css';

const App = () => {
  return (
    <AuthProvider>
      <UnreadCountProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Router>
            <Routes>
              {routes.map((route, index) => (
                route.isPublic ? 
                <Route 
                  key={index} 
                  path={route.path} 
                  element={
                    <TransitionGroup>
                      <CSSTransition
                        key={route.path}
                        classNames="page-transition"
                        timeout={300}
                      >
                        <route.component />
                      </CSSTransition>
                    </TransitionGroup>
                  } 
                /> :
                <Route 
                  key={index} 
                  path={route.path} 
                  element={
                    <ProtectedRoute requiredRole={route.requiredRole}>
                      <TransitionGroup>
                        <CSSTransition
                          key={route.path}
                          classNames="page-transition"
                          timeout={300}
                        >
                          <route.component />
                        </CSSTransition>
                      </TransitionGroup>
                    </ProtectedRoute>
                  } 
                />
              ))}
            </Routes>
          </Router>
        </ThemeProvider>
      </UnreadCountProvider>
    </AuthProvider>
  );
};

export default App;
